import { ValidCompanyDataErrors } from "../types/user";

export enum InputErrors {
  EMPTY = "Заполните поле",
  NOT_FOUND_INN = "По данному инн ничего не найдено",
  BAD_LEN = "Не валидная длинна поля",
  BAD_EMAIL = "Не валидный email",
  BUSSY_PHONE = 'Данный номер уже занят',
}

export const UserInputsValidLength: {
  [key in keyof ValidCompanyDataErrors]: number[];
} = {
  inn: [10, 12],
  phone: [18],
  ogrn: [13, 15],
  kpp: [9],
};
