import { getOnlyNumbers } from "./../constants/composeString";
import { CompanyData, ValidCompanyDataErrors } from "./../types/user";
import { Endpoints } from "../constants/constants";
import api from "../utils/api";
import apiWithoutToken from "../utils/apiWithoutToken";
import { InputErrors } from "../constants/valids";
import { UserTypes } from "../constants/user";

type ErrorType = {
  status?: string;
  message?: string;
};

type RegisterUserData = {
  data: CompanyData | [];
  errors: (ValidCompanyDataErrors & ErrorType) | null;
};

type InnDataAnswer = {
  kpp: string;
  management: {
    name: string;
  };
  name: {
    full_with_opf: string;
    short_with_opf: string;
  };
  inn: string;
  ogrn: string;
  type: "LEGAL" | false;
  fio: {
    name: string;
    patronymic: string;
    surname: string;
  };
  okved: string | null;
};

type InnRequestAnswer = {
  data: {
    data: Array<{ data: InnDataAnswer }>;
    success: boolean;
  };
};

export class RegistrationService {
  static async registarData(data: CompanyData): Promise<RegisterUserData> {
    const result: RegisterUserData = {
      data: [],
      errors: null,
    };
    const response = await apiWithoutToken.post(Endpoints.REGISTER, {
      ...data,
      phone: getOnlyNumbers(data.phone),
    });
    const redData = response.data;
    if (redData.errors?.status === 404) {
      result.errors = { inn: [InputErrors.NOT_FOUND_INN] };
    } else {
      result.data = redData.data;
      result.errors = redData.success ? null : redData.errors;
    }
    return result;
  }

  static async getOrganisationInfoByTin(tin: string) {
    const response: InnRequestAnswer = await api.post(
      `${Endpoints.ORG_DATA}?inn=${tin}`
    );
    if (!response.data?.success) return null;
    const data = response.data.data[0].data;
    const isLegal = data.type === "LEGAL";
    const formOfOwnership = isLegal ? UserTypes.legal : UserTypes.individual;

    return {
      "name-organization": data.name?.short_with_opf || "",
      "full-name": data.name?.full_with_opf || "",
      "general-director": data.management?.name || "",
      inn: data?.inn || "",
      ogrn: data?.ogrn || "",
      kpp: data?.kpp || "",
      "type-of-ownership": formOfOwnership,
      "name-ip": data?.fio?.name || "",
      "surname-ip": data?.fio?.surname || "",
      "patronymic-ip": data?.fio?.patronymic || "",
      okveds: data?.okved || "",
    };
  }
}
