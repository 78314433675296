import { FC, useCallback, useState } from "react";
import classNames from "classnames";
import { RouteProps, useLocation } from "react-router-dom";
import styles from "./Layout.module.scss";
import { ReactComponent as HomeIcon } from "../../../assets/icons/layout/Home.svg";
import { ReactComponent as DriversIcon } from "../../../assets/icons/layout/Drivers.svg";
import { ReactComponent as HistoryIcon } from "../../../assets/icons/layout/History.svg";
import { ReactComponent as ProfileIcon } from "../../../assets/icons/layout/User.svg";
import { ReactComponent as MapIcon } from "../../../assets/icons/layout/map.svg";
import { ReactComponent as MenuIcon } from "../../../assets/icons/menuMobile.svg";
import { ReactComponent as MenuCloseIcon } from "../../../assets/icons/menuclose.svg";
import { ReactComponent as BallonIcon } from "../../../assets/icons/layout/Ballon.svg";



import { ReactComponent as LogOutIcon } from "../../../assets/icons/layout/LogOut.svg";

import { Routes } from "../../../constants/config";
import MenuItem from "./parts/MenuItem/MenuItem";
import { useAppDispatch, useAppSelector } from "../../../hooks/store";
import { logout, selectIsAuth } from "../../../store/slice/auth";
export type MenuItemType = {
  icon: JSX.Element;
  label: TextLayout;
  route: Routes;
  subRoutes?: Routes[];
};
export enum TextLayout {
  HOME = "Главная",
  REPORT = "История заправок",
  BALLONS = "Баллоны",
  HISTORY = "История операций",
  PROFILE = "Профиль",
  MAP = "Карта АГЗС",
}

const _MENU: MenuItemType[] = [
  {
    icon: <HomeIcon />,
    label: TextLayout.HOME,
    route: Routes.HOME,
    subRoutes: [Routes.DRIVERS],
  },
  {
    icon: <DriversIcon />,
    label: TextLayout.REPORT,
    route: Routes.REPORT,
  },
  {
    icon: <BallonIcon />,
    label: TextLayout.BALLONS,
    route: Routes.BALLONS,
  },
  {
    icon: <HistoryIcon />,
    label: TextLayout.HISTORY,
    route: Routes.HISTORY,
  },
  {
    icon: <ProfileIcon />,
    label: TextLayout.PROFILE,
    route: Routes.PROFILE,
  },
  {
    icon: <MapIcon />,
    label: TextLayout.MAP,
    route: Routes.MAP,
  },
];

const Layout: FC<RouteProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(selectIsAuth);
  const blockedScreen = useAppSelector((state) => state.app.blockedPage);
  const location = useLocation().pathname as Routes;
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const toggleMenu = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);
  /**
   * logout
   */
  const onLogOut = useCallback(async () => {
    dispatch(logout());
    window.location.reload();
  }, []);

  const isFocused = useCallback(
    (menuItem: MenuItemType) => {
      if (blockedScreen === menuItem.route) {
        return true;
      }
      return !!menuItem?.subRoutes?.find((subRoute) => {
        const clearSubRoute = subRoute.replaceAll("/", "");
        const clearLocation = location.replaceAll("/", "");
        return clearLocation === clearSubRoute;
      });
    },
    [blockedScreen, location]
  );

  return (
    <div className={styles.root}>
      <div className={styles.mobileMenu} onClick={toggleMenu}>
        {isMenuOpen ? <MenuIcon /> : <MenuCloseIcon className={styles.mobileMenuClose} />}
      </div>
      <div className={classNames(styles.left, { [styles.mobileMenuHide]: isMenuOpen })}>
        <div className={classNames(styles.block, styles.top)}>
          <img src="logo.png" alt="Logo" className={styles.logo} />
        </div>
        <div className={styles.leftMenuWrapper}>
          <div className={styles.menu} onClick={toggleMenu}>
            {_MENU.map((menuItem) => (
              <MenuItem
                key={menuItem.route}
                data={menuItem}
                isFocused={isFocused(menuItem)}
                isBlocked={!!blockedScreen && blockedScreen !== menuItem.route}
              />
            ))}
          </div>
          {isAuth && (
            <div className={styles.logOutBlock}>
              <LogOutIcon className={styles.logOut} onClick={onLogOut} />
            </div>
          )}
        </div>
      </div>
      <div className={styles.right}>
        <div style={{ color: 'red' }} className={classNames(styles.block, styles.top)}>
          Личный кабинет находится в режиме тестирования. Пожелания направляйте на email: ggroup-ural@mail.ru
        </div>
        <div className={styles.innerContent}>{children}</div>
      </div>
    </div>
  );
};
export default Layout;
