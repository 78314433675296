import styles from "../DriverCard.module.scss";
import { Driver, ValidDriverErrors } from "../../../../types/driver";
import { TextWithHintIcon } from "../../../simples/TextWithHintIcon";
import { Input } from "../../../simples/Input";
import { InputAfetIcons, TypeInput } from "../../../simples/Input/Input";
import { HintText } from "../../../simples/HintText";
import { PROHIBITION_GAS_STATIONS } from "../../../../constants/hints";
import { CheckBox } from "../../../simples/CheckBox";
import { ReactComponent as Plus } from "../../../../assets/icons/plusBold.svg";
import { useCallback, useEffect, useState } from "react";
import { Station } from "../../../../types/types";
import { ModalAddGasStation } from "../../../page/DriversEdit/parts/DriversEditCard/ModalAddGasStation";
import { Stations } from "../../../../services/Stations";
import { DeleteButton } from "../../../simples/DeleteButton";
import { useNavigate, useParams } from "react-router-dom";
import { ModalBase } from "../../../simples/ModalBase";
import { useAppDispatch } from "../../../../hooks/store";
import { setIsLoading } from "../../../../store/slice/app";
import { Drivers } from "../../../../services/Drivers";



type Props = {
  data: Driver;
  setData: (field: keyof Driver, value: any) => void;
  errors: ValidDriverErrors | undefined;
  isEdditing?: boolean;
};

enum Modals {
  CHOOSE_STATION = "CHOOSE_STATION",
  CONFIRM_DELETE = "CONFIRM_DELETE",
}

const RighPartDriverCard = ({ data, setData, errors, isEdditing }: Props) => {
  const [modal, setModal] = useState<Modals | null>(null);
  const [uniqueStation, setUniqueStation] = useState<Station[]>([]);
  const [allowedStation, setAllowedStation] = useState<Station[]>([]);

  const params = useParams();
  const driverId = params ? params?.id : -1;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const removeDriver = async () => {
    dispatch(setIsLoading(true));
    setModal(null);
    driverId && (await Drivers.deleteDriver(+driverId));
    dispatch(setIsLoading(false));
    navigate(-1);
  };

  // delete station
  const onRemoveStation = (id: number) => {
    const newPerrmuttedGasStation = data["stationsBan"].filter(
      (item) => item.id !== id
    );
    setData("stationsBan", newPerrmuttedGasStation);
  };

  const onAddStations = useCallback(
    async (newStations: Station[]) => {

      const allStations = [...uniqueStation];
      const newStationIds = new Set(newStations.map(station => station.id));
      const updatedStationsBan = allStations.filter(station => !newStationIds.has(station.id));
      await setData("stationsBan", updatedStationsBan);
      setAllowedStation(allStations.filter(station => newStationIds.has(station.id)))
    },
    [setData]
  );

  const isUnlimetGasStation = !data.stationsBan.length;

  const onClearMonth = () => setData("limitLitresMonth", "");
  const onClearDay = () => setData("limitLitresDay", "");
  const onClearStation = useCallback(() => {
    if (isUnlimetGasStation) {
      setData("stationsBan", []);
    } else {
      setData("stationsBan", uniqueStation);
    }
  }, [setData, isUnlimetGasStation, uniqueStation]);

  useEffect(() => {
    const fetchStations = async () => {
      if (uniqueStation.length === 0) {
        const response = await Stations.getStations();
        setUniqueStation(response);
      }
      const sorted = async () => {
        const stationIdsInBan = new Set(data.stationsBan.map(station => station.id));
        const filteredAllowedStations = uniqueStation.filter(station => !stationIdsInBan.has(station.id));
        await setAllowedStation(filteredAllowedStations);
      }
      sorted()
    };

    fetchStations();

  }, [data.stationsBan]);



  const isUnlimedDays = +data.limitLitresDay === 0;
  const isUnlimedMonth = +data.limitLitresMonth === 0;

  const isMaxStation = data["stationsBan"].length >= 5;
  const isOneStation = data["stationsBan"].length === 1;

  return (
    <div className={styles.topSiteContainer}>
      <TextWithHintIcon
        classes={{ root: styles.topTitleHint }}
        title="Лимит литров на месяц*"
      />
      <Input
        error={errors?.limitLitresMonth && errors.limitLitresMonth[0]}
        classes={{ input: styles.input }}
        onChange={setData.bind(null, "limitLitresMonth")}
        value={isUnlimedMonth ? "" : data.limitLitresMonth}
        placeholder="-"
        typeProps={TypeInput.NUMBER}
        afterIcon={isEdditing && InputAfetIcons.PENSIL}
      />
      <CheckBox
        onChange={onClearMonth}
        checked={isUnlimedMonth}
        classes={{ root: styles.checkBox }}
        label="Без ограничений в месяц"
      />
      <TextWithHintIcon
        classes={{ root: styles.title }}
        title="Лимит литров на день*"
      />
      <Input
        typeProps={TypeInput.NUMBER}
        error={errors?.limitLitresDay && errors.limitLitresDay[0]}
        classes={{ input: styles.input }}
        onChange={setData.bind(null, "limitLitresDay")}
        value={isUnlimedDays ? "" : data.limitLitresDay}
        placeholder={"-"}
        afterIcon={isEdditing && InputAfetIcons.PENSIL}
      />
      <CheckBox
        onChange={onClearDay}
        checked={isUnlimedDays}
        classes={{ root: styles.checkBox }}
        label="Без ограничений в день"
      />
      <TextWithHintIcon
        classes={{ root: styles.title }}
        title="Разрешенные заправки"
      />

      <div className={styles.stationBans}>
        {uniqueStation && allowedStation.map((el, index) => (
          <Input
            color="#000"
            disabled
            onIconPress={
              index !== 0 || isOneStation
                ? onRemoveStation.bind(null, el.id)
                : undefined
            }
            afterIcon={
              index === 0
                ? isOneStation
                  ? InputAfetIcons.TRASH
                  : InputAfetIcons.PENSIL
                : InputAfetIcons.TRASH
            }
            key={el.id}
            classes={{ input: styles.input, root: styles.inputContainer }}
            onChange={setData.bind(null, "limitLitresMonth")}
            value={el.name}
          />
        ))}
      </div>

      {!allowedStation.length && (
        <Input
          color="#000"
          disabled
          classes={{ input: styles.input }}
          onChange={setData.bind(null, "limitLitresMonth")}
          value={""}
          placeholder={"-"}
        />
      )}
      <span className={styles.inputButtons}>
        <CheckBox
          onChange={() => { !isUnlimetGasStation ? onClearStation() : null }}
          checked={isUnlimetGasStation}
          label="Без ограничений"
        />
        <button
          className={styles.plusButton}
          onClick={setModal.bind(null, Modals.CHOOSE_STATION)}
        >
          <Plus />
        </button>
      </span>
      <span className={styles.hint}>
        <HintText mt={24}>{PROHIBITION_GAS_STATIONS}</HintText>
      </span>
      {isEdditing && (
        <DeleteButton
          className={styles.deleteButton}
          title={"Удалить водителя"}
          onClick={setModal.bind(null, Modals.CONFIRM_DELETE)}
        />
      )}
      {modal === Modals.CHOOSE_STATION && (
        <ModalAddGasStation
          onClose={setModal.bind(null, null)}
          addStations={onAddStations}
          allGasStation={uniqueStation}
        />
      )}
      {modal === Modals.CONFIRM_DELETE && (
        <ModalBase
          onClickYes={removeDriver}
          onClickNo={setModal.bind(null, null)}
          title={`Удалить водителя\n${data["fio"]}?`}
        />
      )}
    </div>
  );
};

export default RighPartDriverCard;
