import classNames from "classnames";
import React, { FC, useState } from "react";
import { ElementWrapper } from "../../../../simples/ElementWrapper";
import styles from "./BottomSummaryTable.module.scss";
import { ReactComponent as CalendarIcon } from "../../../../../assets/icons/calendarIcon.svg";
import { ModalClose } from "../../../../simples/ModalClose";
import { DatePickerBlock } from "../../../../complexes/DatePickerBlock";
import { Button } from "../../../../simples/Button";
import { thousand } from "../../../../../constants/helper";
import { TransShortType } from "../../../../../types/types";
import { ScrollBlock } from "../../../../simples/ScrollBlock";
import { TransactionsServices } from "../../../../../services/Transactions";

type Props = {
  list: TransShortType[];
  onFilter: (date: string) => void;
  scrollReach: () => void;
  totalLiters: number;
  totalSpend: number;
};

const BottomSummaryTable: FC<Props> = ({
  list,
  totalLiters,
  totalSpend,
  scrollReach,
  onFilter,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [dates, setDates] = useState<string[]>([]);

  const sendDate = () => {
    if (dates.length > 1) {
      const datesStr = `${dates[0].split("-").join("-")} - ${dates[
        dates.length - 1
      ]
        .split("-")
        .join("-")}`;
      onFilter(datesStr);
    }
    setShowModal(false);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };
  const onModal = () => {
    setShowModal(true);
  };
  const exportFun = async () => {
    try {
      const response = await TransactionsServices.loadTransactions({
        xlsExport: 1,
        offset: 0,
        limit: 30,
        start: dates[0] || undefined,
        end: dates[dates.length - 1] || undefined,
        spend: 1,
      });

      const link = document.createElement("a");
      link.href = response.fileName;
      link.setAttribute("download", "file.xls"); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("loadTransactions", error);
    }
  };

  return (
    <>
      <div className={styles.root}>
        <div className={styles.content}>
          <div className={styles.headerContainer}>
            <div className={styles.headerLeft}>
              <h2 className={styles.headerTitle}>
                Краткий отчет за {!dates.length ? "сегодня" : ""}
              </h2>
              <span className={styles.date}>
                {dates.length > 1 &&
                  `${dates[0].split("-").reverse().join(".")} - ${dates[
                    dates.length - 1
                  ]
                    .split("-")
                    .reverse()
                    .join(".")}`}
              </span>
            </div>
          </div>

          <div className={classNames(styles.table, styles.boxShadowNone)}>
            <div className={styles.header}>
              <div className={styles.headerItem}>ФИО</div>
              <div className={styles.headerItem}>Номер авто</div>
              <div className={styles.headerItem}>Кол-во литров</div>
              <div className={classNames(styles.headerItem, styles.cost)}>
                Сумма
              </div>
            </div>
          </div>
          <ScrollBlock
            className={styles.scrollBlock}
            onReachedEnd={scrollReach}
          >
            {list.map((item) => (
              <div className={styles.tr} key={item.id}>
                <div className={styles.bodyItem}>{`${item.fio || ""}`}</div>
                <div className={classNames(styles.bodyItem, styles.gosNumber)}>
                  {item.autoNum}
                </div>
                <div className={classNames(styles.bodyItem, styles.liters)}>
                  {item.litres}
                </div>
                <div className={classNames(styles.bodyItem, styles.cost)}>
                  {thousand(item.sum)} ₽
                </div>
              </div>
            ))}
          </ScrollBlock>
        </div>
        <div className={styles.tableSum}>
          <div className={styles.result}>Итого:</div>
          <div className={classNames(styles.resultItem)}> </div>

          <div className={classNames(styles.resultItem)}>
            {thousand(totalLiters || 0)}
          </div>
          <div
            className={classNames(styles.resultItem)}
            style={{ justifyContent: "end", paddingRight: "34px" }}
          >
            {thousand(totalSpend || 0)} ₽
          </div>
        </div>
      </div>
      {showModal && (
        <ModalClose
          title="Период"
          onClose={onCloseModal}
          classes={{
            body: styles.modal,
          }}
        >
          <DatePickerBlock isPeriod={1} onChange={setDates} values={dates} />
          <Button onClick={sendDate} className={styles.button}>
            Подтвердить
          </Button>
        </ModalClose>
      )}
    </>
  );
};

export default BottomSummaryTable;
