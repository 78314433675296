import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ScreenWrapper } from "../../complexes/ScreenWrapper";
import styles from "./DriverCreate.module.scss";
import { Drivers as driversServices } from "../../../services/Drivers";
import { DriverCard } from "../../complexes/DriverCard";
import { Driver, ValidDriverErrors } from "../../../types/driver";
import { initialDriver } from "../../../constants/driver";
import { useAppDispatch, useAppSelector } from "../../../hooks/store";
import { setIsLoading } from "../../../store/slice/app";
import { InputErrors } from "../../../constants/valids";

export enum PeriodLiters {
  DAY = "day",
  MONTH = "month",
}

function DriverCreate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [initialState, setInitialState] = useState(initialDriver);
  const [errors, setErrors] = useState<ValidDriverErrors>({});
  const isLoading = useAppSelector((state) => state.app.isLoading);

  const submit = (driver: Driver) => {
    dispatch(setIsLoading(true));
    driversServices
      .createDriver({
        email: driver.email,
        codeAuto: driver.codeAuto,
        password: driver.password,
        stations_ids: driver.stationsBan.map((el) => el.id),
        phone: driver.phone,
        fio: driver.fio,
        autoNum: driver.autoNum,
        autoMark: driver.autoMark,
        limitLitresDay: +driver.limitLitresDay,
        limitLitresMonth: +driver.limitLitresMonth,
      })
      .then((res) => {
        const resErr = res.errors;
        dispatch(setIsLoading(false));
        if (Object.values(res?.errors)?.length) {
          const backErrors: ValidDriverErrors = {};
          if (resErr?.username) backErrors.phone = [InputErrors.BUSSY_PHONE];
          if (resErr?.codeAuto) backErrors.codeAuto = resErr?.codeAuto;
          if (resErr?.email) backErrors.email = resErr.email;
          setErrors(backErrors);
          return;
        }
        res.data?.id && navigate(-1);
      })
      .catch((err) => {
        console.log("🚀 ~ file: DriverCreate.tsx:53 ~ submit ~ err:", err);
        dispatch(setIsLoading(false));
      });
  };

  useEffect(() => {
    (async () => {
      const code = await driversServices.getDriverCode();
      setInitialState((state) => ({ ...state, codeAuto: code }));
    })();
  }, []);

  return (
    <>
      <ScreenWrapper isLoading={isLoading} title={"Создание водителя"}>
        <div className={styles.editWrapper}>
          <DriverCard
            backError={errors}
            isEdditting={false}
            onSubmit={submit}
            initialState={initialState}
            buttonText={"Создать водителя"}
          />
        </div>
      </ScreenWrapper>
    </>
  );
}

export default DriverCreate;
