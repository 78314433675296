import classNames from 'classnames'
import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import styles from './Pagination.module.scss'
type Props = {
    allQuantity: number
    itemNumberPage: (pageNumber: number) => void
    itemOnPage: number
}

const Pagination: FC<Props> = ({ allQuantity, itemOnPage, itemNumberPage }) => {

    const [pageNumber, setPageNumber] = useState<number>(1)
    const [arrPage, setArrPage] = useState<number[]>([])
    const [nextPageStart, setNextPageStart] = useState<number>(0)
    const [showStartArrow, setShowStartArrow] = useState<boolean>(false)
    const [showEndArrow, setShowEndArrow] = useState<boolean>(true)
    const [nextPageEnd, setNextPageEnd] = useState<number>(9)




    useEffect(() => {
        itemNumberPage(pageNumber)
        const arr = arrPage.slice(nextPageStart, nextPageEnd)
        if (pageNumber === arr[arr.length - 1]) {
            if (pageNumber === arrPage.at(-1)) {
                setShowEndArrow(false)
                setShowStartArrow(true)
                return
            }
            nextPagefun()
        } else if (pageNumber === arr[0]) {
            if (pageNumber === arrPage[0]) {
                setShowEndArrow(true)
                setShowStartArrow(false)
                return
            }
            prevPagefun()
        }

        if (pageNumber !== arr[arr.length - 1]) {
            setShowEndArrow(true)
            // setShowStartArrow(true)
        }

        if (pageNumber !== arr[0]) {
            // setShowEndArrow(true)
            setShowStartArrow(true)
        }


        if (arr[0] !== 1) {
            setShowStartArrow(true)
        }

    }, [pageNumber])

    const all = Math.round(allQuantity / itemOnPage) <= 0 ? 1 : Math.round(allQuantity / itemOnPage)

    useEffect(() => {
        const arr: number[] = []
        for (let i = 1; i <= all; i++) {
            arr.push(i)
        }
        setArrPage(arr)
        // if (pageNumber > 0)
        setShowStartArrow(false)
    }, [allQuantity])

    const nextPagefun = () => {
        setNextPageStart(prev => prev + 1)
        setNextPageEnd(prev => prev + 1)
    }
    const prevPagefun = () => {
        setNextPageStart(prev => prev - 1)
        setNextPageEnd(prev => prev - 1)
    }
    const editPage = (numberPage: number) => {
        setPageNumber(prev => prev + numberPage)
    }
    return (
        <div className={styles.pagination}>
            {(showStartArrow && Math.round(allQuantity / itemOnPage) > 0) && <span onClick={() => editPage(-1)} className={styles.paginationItemArrowEnd}>{'<<'}</span>}
            {arrPage.slice(nextPageStart, nextPageEnd).map((item, i) =>
                <span
                    key={i}
                    onClick={() => setPageNumber(item)}
                    className={classNames(styles.paginationItem, {
                        [styles.activePage]: pageNumber === item
                    })} >
                    {item}
                </span>)
            }

            {(showEndArrow && Math.round(allQuantity / itemOnPage) >  0) && <span onClick={() => editPage(1)} className={styles.paginationItemArrowEnd}>{'>>'}</span>}
        </div >)
}

export default Pagination