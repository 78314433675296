import { BlockWrapper } from "../../../../simples/BlockWrapper";
import styles from "./PersonalBlock.module.scss";
import { Drivers as driversServices } from "../../../../../services/Drivers";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../../../constants/config";
import { ReactComponent as FilledDriver } from "../../../../../assets/icons/filledDriver.svg";
import { Button } from "../../../../simples/Button";
import { useCallback, useEffect, useState } from "react";

const PersonalBlock = () => {
  const navigate = useNavigate();
  const [driversCount, setDribersCount] = useState(0);

  const goToDrivers = () => {
    navigate(`/${Routes.DRIVERS}`);
  };
  const goToDriverCreate = () => {
    navigate(Routes.DRIVERS_CREATE);
  };

  const getDrivers = useCallback(async () => {
    try {
      const response = await driversServices.getDrivers({
        offset: 0,
        limit: 9999,
      });

      setDribersCount(response.length);
    } catch (error) {
      console.error("erroe drives ", error);
    }
  }, []);

  useEffect(() => {
    getDrivers();
  }, []);

  return (
    <BlockWrapper isFull>
      <div className={styles.mainPageItemWrapper}>
        <h3 className={styles.title}>Сотрудники</h3>
        <div className={styles.countContainer}>
          <FilledDriver className={styles.icon} />
          <p className={styles.count}>{driversCount}</p>
        </div>
        <div className={styles.buttons}>
          <Button onClick={goToDrivers} className={styles.addDriver}>
            <p className={styles.buttonAddTitle}>Все сотрудники</p>
          </Button>
          <Button onClick={goToDriverCreate} className={styles.allDrivers}>
            <p className={styles.buttonAllTitle}>Добавить сотрудника</p>
          </Button>
        </div>
      </div>
    </BlockWrapper>
  );
};

export default PersonalBlock;
