import { useCallback, useEffect, useState } from "react";
import { UserTypes } from "../../../constants/user";
import { InputErrors, UserInputsValidLength } from "../../../constants/valids";
import useDebounce from "../../../hooks/lifecycle";
import { RegistrationService } from "../../../services/Registrations";
import { CompanyData, ValidCompanyDataErrors } from "../../../types/user";
import { validateEmail } from "../../../utils/valid";
import { Button } from "../../simples/Button";
import LeftPartUserFields from "./parts/LeftPartUserFields";
import RightPartIndividualUserFields from "./parts/RightPartIndividualUserFields";
import RightPartLegalUserFields from "./parts/RightPartLegalUserFields";
import styles from "./UserFields.module.scss";
import { HintText } from "../../simples/HintText";
import { GENERATE_PASSWORD_HINT } from "../../../constants/hints";

type Props = {
  onSubmit: (data: CompanyData) => Promise<boolean | void>;
  buttonText: string[];
  backError: ValidCompanyDataErrors | undefined;
  initialState: CompanyData;
  fromProfile?: boolean;
};

const ipFields = ["patronymic-ip", "name-ip", "surname-ip", "okveds"];
const legalField = [
  "name-organization",
  "general-director",
  "full-name",
  "kpp",
  "okveds",
];

const UserFields = ({
  onSubmit,
  buttonText,
  backError,
  initialState,
  fromProfile,
}: Props) => {
  const [isBeenChange, setIsBeenChange] = useState(false);
  const [errors, setErrors] = useState<ValidCompanyDataErrors>({});
  const [data, setData] = useState(initialState);
  const debouncedInn = useDebounce(data.inn, 1000);

  const setFieldOfData = (field: keyof CompanyData, value: string) => {
    setData((prevState) => ({ ...prevState, [field]: value }));
    !isBeenChange && setIsBeenChange(true);
    errors &&
      setErrors((prev) => {
        delete prev[field];
        return prev;
      });
  };

  const submit = async () => {
    if (buttonText.length === 2 && !isBeenChange) {
      setIsBeenChange(true);
      return;
    }

    if (validAllFields()) {
      const isSuccessSubmit = await onSubmit(data);
      isSuccessSubmit && setIsBeenChange(false);
    }
  };

  const validAllFields = () => {
    let isValid = true;

    Object.keys(data).map((dataKey) => {
      //pass if another user type field
      if (isLegalType && ipFields.includes(dataKey)) {
        return;
      }
      if (!isLegalType && legalField.includes(dataKey)) {
        return;
      }
      const valueLength = data[dataKey as keyof CompanyData]?.length || 0;
      //is too small
      if (valueLength < 2) {
        errors
          ? setErrors((prev) => ({ ...prev, [dataKey]: [InputErrors.BAD_LEN] }))
          : setErrors({ [dataKey]: [InputErrors.BAD_LEN] });
        isValid = false;
      }
      //Is good length
      if (UserInputsValidLength[dataKey as keyof CompanyData]) {
        const isGoodLenth =
          UserInputsValidLength[dataKey as keyof CompanyData]?.includes(
            valueLength
          );
        if (!isGoodLenth) {
          isValid = !isGoodLenth;
          setErrors((prev) =>
            prev
              ? { ...prev, [dataKey]: [InputErrors.BAD_LEN] }
              : { [dataKey]: [InputErrors.BAD_LEN] }
          );
        }
      }
      //Is empty
      if (!valueLength) {
        isValid = false;
        errors
          ? setErrors((prev) => ({ ...prev, [dataKey]: [InputErrors.EMPTY] }))
          : setErrors({ [dataKey]: [InputErrors.EMPTY] });
      }
    });

    //Is bad email
    if (validateEmail(data.email) === null) {
      isValid = false;
      errors
        ? setErrors((prev) => ({ ...prev, email: [InputErrors.BAD_EMAIL] }))
        : setErrors({ email: [InputErrors.BAD_EMAIL] });
    }

    isValid && setErrors({});

    return isValid;
  };
  const updateData = useCallback(async (inn: string) => {
    const orgData = await RegistrationService.getOrganisationInfoByTin(inn);
    if (orgData) {
      setData((prev) => ({ ...prev, ...orgData }));
    } else {
      setErrors((prev) =>
        prev
          ? { ...prev, inn: [InputErrors.NOT_FOUND_INN] }
          : { inn: [InputErrors.NOT_FOUND_INN] }
      );
    }
  }, []);

  //on type inn field debaunced
  useEffect(() => {
    const isIndividualInn = data.inn.length === 10;
    const isLegalInn = data.inn.length === 12;
    const isEmptyProfile = !!data["form-of-organization"];
    const isGoodInn = isIndividualInn || isLegalInn;
    const isChangedFromProfile = !fromProfile || isBeenChange;
    if (isGoodInn && debouncedInn && isChangedFromProfile) {
      (isEmptyProfile || !fromProfile) && updateData(data.inn);
    }
  }, [debouncedInn]);

  // set initial data;
  useEffect(() => {
    fromProfile && setData(initialState);
    !fromProfile && !data.inn && setData(initialState);
    !fromProfile && setIsBeenChange(true);
  }, [initialState]);

  //set errors from back
  useEffect(() => {
    backError &&
      setErrors((prev) => (prev ? { ...prev, ...backError } : backError));
  }, [backError]);
  const isLegalType = data["type-of-ownership"] === UserTypes.legal;

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <LeftPartUserFields
          errors={errors}
          setData={setFieldOfData}
          data={data}
          isBeenChange={isBeenChange}
        />
        {isLegalType ? (
          <RightPartLegalUserFields
            errors={errors}
            data={data}
            setData={setFieldOfData}
            isBeenChange={isBeenChange}
          />
        ) : (
          <RightPartIndividualUserFields
            errors={errors}
            data={data}
            setData={setFieldOfData}
          />
        )}
      </div>
      <div className={styles.button}>
        <span className={styles.hintMobile}>
          <HintText mt={24}>{GENERATE_PASSWORD_HINT}</HintText>
        </span>
        <Button onClick={submit} disabled={!!Object.keys(errors).length}>
          {buttonText && isBeenChange && buttonText[1]
            ? buttonText[1]
            : buttonText[0]}
        </Button>
      </div>
    </div>
  );
};
export default UserFields;
