import produce from 'immer';
import { FC, useContext } from 'react';
import { Station } from '../../../../../../../types/types';
import { CheckBox } from '../../../../../../simples/CheckBox';
import { GasStationContext } from '../ModalAddGasStationContext';
import styles from './ItemDropDown.module.scss';

type Props = {
  item: Station
  values: Array<number>
}

const ItemDropDown: FC<Props> = ({ item, values }) => {
  const [dropDownValue, setDropDownValue] = useContext(GasStationContext);
  const editCheck = () => {
    if (values.includes(item.id)) {
      setDropDownValue(prev => prev.filter(i => i.id !== item.id))
    } else {
      setDropDownValue(produce(
        draft => {
          draft.push(item)
        }
      ))
    }
  };
  return (
    <div
      className={styles.wrapper}>
      <div className={styles.left}>
        <h4>{item.name}</h4>
        <p>{item.address}</p>
      </div>
      <div className={styles.right}>
        <CheckBox checked={values.includes(item.id)} onChange={editCheck} />
      </div>
    </div>
  );
};

export default ItemDropDown;
