import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { ItemHolder } from '../../../../simples/ItemHolder'
import { ModalClose } from '../../../../simples/ModalClose'
import styles from './HistoryModal.module.scss'
import { CheckBox } from '../../../../simples/CheckBox'
import { DatePickerBlock } from '../../../../complexes/DatePickerBlock'
import { Button } from '../../../../simples/Button'
type Props = {
    onClose: () => void
    onFilter: (data: FilterHistoryType) => void
}
export type FilterHistoryType = {
    addBalance: boolean
    removeBalance: boolean
    period: string
}
const HistoryModal: FC<Props> = ({ onClose,
    onFilter }) => {
    const [dates, setDates] = useState<string[]>([]);
    const [filter, setFilter] = useState<FilterHistoryType>({
        addBalance: false,
        removeBalance: false
    } as FilterHistoryType)
    const setValue = (value: string | boolean, key: keyof FilterHistoryType) => {
        setFilter(produce(
            draft => {
                const f: any = draft
                f[key] = value
            }
        ))
    }

    const dateParser = () => {
        if (dates.length > 1) {
            const datesStr = `${dates[0].split('-').reverse().join('.')} - ${dates[dates.length - 1].split('-').reverse().join('.')}`
            setValue(datesStr, 'period')
        }


    }
    useEffect(() => {

        dateParser()

    }, [dates.length])

    const sendFilter = () => {
        onFilter({
            removeBalance: filter.removeBalance === filter.addBalance ? false : filter.removeBalance,
            addBalance: filter.addBalance === filter.removeBalance ? false : filter.addBalance,
            period: filter.period
        })
        onClose()
    }

    return (
        <ModalClose
            title={'Сортировка'}
            onClose={onClose}
            classes={
                {
                    modal: styles.modal
                }
            }
        >
            <div className={styles.filterWrapper}>
                <ItemHolder flex>
                    <span className={styles.checkItem}>Приход</span>
                    <CheckBox
                        checked={filter.addBalance}
                        onChange={() => setValue(!filter.addBalance, 'addBalance')}
                    />
                </ItemHolder>
                <ItemHolder flex>
                    <span className={styles.checkItem}>Расход</span>
                    <CheckBox
                        checked={filter.removeBalance}
                        onChange={() => setValue(!filter.removeBalance, 'removeBalance')}
                    />
                </ItemHolder>
                <ItemHolder>
                    <DatePickerBlock
                        isPeriod={1}
                        onChange={setDates}
                        values={dates}
                    />
                </ItemHolder>
                <Button onClick={sendFilter} className={styles.button}>Подтвердить</Button>
            </div>

        </ModalClose >
    )
}

export default HistoryModal

