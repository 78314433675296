import React, { FC, ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import style from './Button.module.scss';

export enum ButtonVariants {
  Contained = 'contained',
  Link = 'link',
}

export enum ButtonColors {
  Default = 'default',
  Primary = 'primary',
  Secondary = 'secondary',
  Light = 'light',
  Main = 'main',
  Cancel = 'cancel',
  Delete = 'delete'
}

export type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  /**
   * Тип кнопки
   */
  variant?: ButtonVariants

  /**
   * Цвет кнопки
   */
  color?: ButtonColors
  disables?: boolean
}

/**
 * Кнопка
 * @param {ButtonVariants} variant - Вариант отображения кнопки
 * @param {ButtonColors} color - Цветовая гамма кнопки
 * @param children - Содержимое кнопки
 * @param className - Дополнительные классы стилей
 * @param rest - Остальные пропсы
 * @constructor
 */
const Button: FC<Props> = ({
  variant = ButtonVariants.Contained,
  color = ButtonColors.Default,
  children,
  disables = false,
  className,
  ...rest
}: Props) => {
  const classes = classNames(style.button, className, {
    [style.containedVariant]: variant === ButtonVariants.Contained,
    [style.linkVariant]: variant === ButtonVariants.Link,
    [style.delete]: color === ButtonColors.Delete,
    [style.defaultColor]: color === ButtonColors.Default,
    [style.primaryColor]: color === ButtonColors.Primary,
    [style.secondaryColor]: color === ButtonColors.Secondary,
    [style.lightColor]: color === ButtonColors.Light,
    [style.mainColor]: color === ButtonColors.Main,
    [style.cancelColor]: color === ButtonColors.Cancel,
    [style.disables]: disables,
  });

  return (
    <button className={classes} {...rest}>
      <span className={style.contain}>{children}</span>
    </button>
  );
};

export default Button;
