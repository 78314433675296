import React, { FC } from 'react';
import classNames from 'classnames';
import style from './MainPreloader.module.scss';

type Props = {
    classes?: {
        loader?: string
    }
}

const MainPreloader: FC<Props> = ({ classes = {} }) => (
  <div className={classNames(style.loader, classes.loader)}>
    <div className={style.loaderInner}>
      <label>●</label>
      <label>●</label>
      <label>●</label>
      <label>●</label>
      <label>●</label>
      <label>●</label>
    </div>
  </div>
);

export default MainPreloader;
