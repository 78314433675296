import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "../../../constants/config";
import { ScreenWrapper } from "../../complexes/ScreenWrapper";
import { RegistationTypeInn } from "./parts/RegistationTypeInn";
import UserFields from "../../complexes/UserFields/UserFields";
import { CompanyData, ValidCompanyDataErrors } from "../../../types/user";
import { useState } from "react";
import { RegistrationService } from "../../../services/Registrations";
import { initialUserData, VatTypes } from "../../../constants/user";
import { useAppDispatch } from "../../../hooks/store";
import styles from "./Registration.module.scss";
import { updateUser } from "../../../store/slice/auth";

type LocationState = {
  isTypeInn: boolean;
  inn?: string;
  vat?: boolean;
};

function Registration() {
  const navigate = useNavigate();
  const [errors, setErrors] = useState<ValidCompanyDataErrors>({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const state = location.state as LocationState;

  const register = async (userData: CompanyData) => {
    setIsLoading(true);
    const data = await RegistrationService.registarData(userData);
    data?.errors && setErrors(data.errors);
    (!data.errors || !Object.keys(data.errors).length) && navigate(Routes.AUTH);
    !data.errors && dispatch(updateUser(data.data));
    setIsLoading(false);
  };

  return (
    <div className={styles.container}>
      <ScreenWrapper isLoading={isLoading} title="Регистрация">
        {state.isTypeInn ? (
          <RegistationTypeInn />
        ) : (
          <UserFields
            backError={errors}
            initialState={{
              ...initialUserData,
              inn: state.inn || "",
              "form-of-organization": state.vat
                ? VatTypes.withVat
                : VatTypes.withoutVat,
            }}
            onSubmit={register}
            buttonText={["Зарегистрироваться"]}
          />
        )}
      </ScreenWrapper>
    </div>
  );
}

export default Registration;
