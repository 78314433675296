import { CompanyData } from "../types/user";

export enum VatTypes {
  "withVat" = "С НДС",
  "withoutVat" = "БЕЗ НДС",
}

export enum UserTypes {
  "individual" = "Индивидуальный предприниматель",
  "legal" = "Юридическое лицо",
}

export const initialUserData: CompanyData = {
  inn: "",
  phone: "",
  email: "",
  "full-name": "",
  ogrn: "",
  kpp: "",
  "name-organization": "",
  "form-of-organization": VatTypes.withoutVat,
  "type-of-ownership": UserTypes.individual,
  "general-director": "",
  "surname-ip": "",
  "name-ip": "",
  "patronymic-ip": "",
  okveds: "",
};
