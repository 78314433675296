import {
  FC, useCallback, useMemo, useState,
} from 'react';
import classNames from 'classnames';
import { CheckBox } from '../../../../../simples/CheckBox';
import { ModalClose } from '../../../../../simples/ModalClose';
import styles from './ModalAddGasStation.module.scss';
import { ReactComponent as Arrow } from '../../../../../../assets/icons/arrowDropDown.svg';
import { ItemDropDown } from './parts';
import { GasStationContext } from './ModalAddGasStationContext';
import { Button } from '../../../../../simples/Button';
import { Station } from '../../../../../../types/types';

type Props = {
  onClose: () => void
  addStations: (id: Station[]) => void
  allGasStation: Station[]
}

const ModalAddGasStation: FC<Props> = ({
  onClose,
  allGasStation,
  addStations
}) => {
  const [selectAll, setSelecALL] = useState<boolean>(false);
  const [openDropDown, setOpenDropDown] = useState<boolean>(false);
  const [dropDownValue, setDropDownValue] = useState<Station[]>([]);




  const addStationsBlock = () => {
    addStations(dropDownValue)
    onClose()
  }
  const dropDownClick = () => {
    setOpenDropDown((prev) => !prev);
  };

  /**
   * @params select all gasstation 
   */
  const allSelect = useCallback(
    () => {
      setSelecALL(prev => !prev);
      if (!selectAll) {
        setDropDownValue(allGasStation);
      } else {
        setDropDownValue((prev) => []);
      }
    },
    [selectAll, allGasStation.length],
  );

  const lables = useMemo((): string => {
    return dropDownValue.map(i => i.name).join(', ')
  }, [dropDownValue])

  return (
    <GasStationContext.Provider value={[dropDownValue, setDropDownValue]}>
      <ModalClose
        title="Добавление заправки"
        onClose={onClose}
        classes={{
          modal: styles.modal,
          body: styles.modalBody,
        }}
      >

        <div className={styles.wrapperTitle}>
          <p className={styles.title}>Заправки</p>
          <CheckBox onChange={allSelect} checked={selectAll} labelLeft="Выбрать все" />
        </div>
        <div className={styles.wrapperDropDown}>
          <div onClick={dropDownClick} className={styles.dropDown}>
            <span className={styles.valueDropDown}>{!dropDownValue.length ? ['Выберите'] : lables}</span>
            <Arrow className={classNames(styles.arrow, {
              [styles.activeArrow]: openDropDown,
            })}
            />
          </div>
          <div
            className={classNames({
              [styles.dropDownList]: !openDropDown,
              [styles.activeDropDown]: openDropDown,
            })}
          >
            {allGasStation.map((item) => <ItemDropDown values={dropDownValue.map(i => i.id)}key={item.id} item={item} />)}
          </div>
        </div>

        <div className={styles.buttonWrapper}>
          <Button
            onClick={addStationsBlock}
          >
            Подтвердить
          </Button>
        </div>
      </ModalClose>
    </GasStationContext.Provider>
  );
};

export default ModalAddGasStation;
