import classNames from 'classnames';
import React, { FC, forwardRef } from 'react';
import ReactInputMask from 'react-input-mask';
import { InputProps } from '../Input/Input';
import { ReactComponent as SearchIcon } from '../../../assets/icons/SearchIcon.svg';
import styles from './Search.module.scss';

type Props = {
  value: string | undefined
  onChange: (e: string) => void
  placeholder?: string
  disabled?: boolean
  classes?: {
    input?: string
    block?: string
  }
  mask?: string | any
  error?: undefined | string
  label?: string
}

const Search: FC<Props> = forwardRef<HTMLInputElement & ReactInputMask, Props>(({
  value,
  onChange,
  placeholder,
  disabled,
  classes,
  mask,
  error,
  label,
}, ref) => (
  <div className={styles.root}>
    {label && <div className={styles.label}>{label}</div>}
    {
      <div className={classNames(styles.inputWrapper, {
        [styles.error]: !!error,
      }, classes?.block)}
      >
        <SearchIcon />
        {!mask
          ? (
            <div style={{
              width: '100%',
            }}
            >

              <input
                ref={ref}

                disabled={disabled}
                className={classNames(styles.input, {
                  [styles.disabled]: disabled,
                  // [styles.error]: !!error
                }, classes?.input)}
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value)}
                value={value}
                min={0}
              />
            </div>
          )
          : (
            <ReactInputMask
              ref={ref}
              disabled={disabled}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              mask={mask}
              className={classNames(styles.input, {
                [styles.disabled]: disabled,
                // [styles.error]: !!error
              }, classes?.input)}
            />
          )}

      </div>
    }
    {error && <div className={styles.errorText}>{error}</div>}
  </div>
));

export default Search;
