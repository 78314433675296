import api from "../utils/api";
import { BACK_PRODUCTION, Endpoints } from "../constants/constants";
import { toQueryString } from "../constants/helper";
import {
  RootShortTransType,
  RootTransType,
  RootFullTransType,
} from "../types/types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { TReportFilter } from "../components/page/Report/parts/ReportFilter";

export type FetchTrans = {
  limit?: number;
  offset?: number;
  start?: string;
  end?: string;
  income?: number;
  spend?: number;
};

export type FetchFullTrans = Partial<TReportFilter> & {
  limit?: number;
  offset?: number;
  from?: string;
  to?: string;
  income?: number;
  spend?: number;
  download?: 0 | 1;
};

const baseUrl = BACK_PRODUCTION;

export const transAPI = createApi({
  reducerPath: "Transactions",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Accept", `application/json`);
      headers.set("Content-Type", `application/json`);
      return headers;
    },
  }),
  tagTypes: ["trans"],
  endpoints: (build) => ({
    fetchAllPosts: build.query<RootTransType, FetchTrans>({
      query: ({ limit, offset, start, end, income, spend }: FetchTrans) => ({
        url: `${Endpoints.TRANSACTIONS}`,
        params: {
          limit,
          offset,
          start,
          end,
          income,
          spend,
        },
      }),
      providesTags: () => ["trans"],
    }),
  }),
});

export class TransactionsServices {
  static async lazyTransactions(data: FetchTrans): Promise<RootShortTransType> {
    const response = await api.get(
      `${Endpoints.SHORT_STATISTIC}${toQueryString(data)}`
    );

    return response.data;
  }

  // /api/v1/legal/transactions?offset=0&limit=30&start=2022-06-15&end=2022-06-17&spend=1&xlsExport=1
  static async loadTransactions(
    data: FetchTrans & { xlsExport: 1 | 0 }
  ): Promise<any> {
    const response = await api.get(
      `${Endpoints.SHORT_STATISTIC}${toQueryString(data)}`
    );

    return response.data.data;
  }

  static async lazyFullTransactions(
    data: FetchFullTrans
  ): Promise<RootFullTransType> {
    const response = await api.get(
      `${Endpoints.FULL_STATISTIC}${toQueryString(data)}`
    );

    return response.data;
  }

  static async loadFullTransactions(
    data: FetchFullTrans
  ): Promise<{ fileName: string }> {
    const response = await api.get(
      `${Endpoints.FULL_STATISTIC}${toQueryString(data)}`
    );

    return response.data.data;
  }

  static async lazyFullBallonsTransactions(
    data: FetchFullTrans
  ): Promise<RootFullTransType> {
    const response = await api.get(
      `${Endpoints.FULL_STATISTIC}${toQueryString({ ...data, ballons: 1 })}`
    );

    return response.data;
  }

  static async loadFullBallonsTransactions(
    data: FetchFullTrans
  ): Promise<{ fileName: string }> {
    const response = await api.get(
      `${Endpoints.FULL_STATISTIC}${toQueryString({ ...data, ballons: 1 })}`
    );

    return response.data.data;
  }
}
