import classNames from "classnames";
import { FC } from "react";
import { RouteProps } from "react-router-dom";
import { MainPreloader } from "../../simples/MainPreloader";
import styles from "./ScreenWrapper.module.scss";

type Props = RouteProps & {
  isLoading?: boolean;
  title?: string;
  topRightSide?: JSX.Element;
  topLeftSide?: JSX.Element;
  classes?: {
    content?: string;
    top?: string;
    root?: string;
  };
  onEndReached?: () => void;
};

const ScreenWrapper: FC<Props> = ({
  isLoading,
  topLeftSide,
  title,
  topRightSide,
  classes,
  children,
}) => (
  <div className={classNames(styles.root, classes?.root)}>
    <div className={classNames(styles.top, classes?.top)}>
      <div className={styles.leftHeader}>
        <h1 className={styles.title}>{title}</h1>
        <div className="">{topLeftSide}</div>
      </div>
      <div className="">{topRightSide}</div>
    </div>
    <div className={classNames(styles.content, classes?.content)}>
      {isLoading && <MainPreloader />}

      {children}
    </div>
  </div>
);
export default ScreenWrapper;
