import axios, { AxiosRequestConfig } from 'axios';
import { BACK_PRODUCTION } from '../constants/constants';
import { BASE_URL } from '../constants/location';

const config: AxiosRequestConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  // transformRequest: (data, headers) => {
  //   // !!! override data to return formData
  //   // since axios converts that to string
  //   return data
  // },
};
config.baseURL = BACK_PRODUCTION
// if (process.env.REACT_APP_API_URL) {
//     config.baseURL = process.env.REACT_APP_API_URL
// }

const instance = axios.create(config);

export default instance;
