import { Station } from "./../types/types";
import { Endpoints } from "../constants/constants";
import { toQueryString } from "../constants/helper";
import { DriversType } from "../types/types";
import api from "../utils/api";

export type DriversParamsType = {
  offset: number;
  limit: number;
  search?: string;
};
export enum BlocedDriver {
  BLOCED,
  NO_BLOCED,
}
export type SaveDriverType = {
  email?: string;
  codeAuto: string;
  password: string;
  phone: string;
  fio: string;
  autoNum: string;
  autoMark: string;
  blocked: BlocedDriver;
  limitLitresDay: number;
  limitLitresMonth: number;
  stations_ids: number[];
  exclude: number[];
};

export type ResDriverType = DriversType & {
  diver: { statitonBan: { station: Station; station_id: number }[] };
};

export class Drivers {
  static async getDrivers(data: DriversParamsType): Promise<DriversType[]> {
    const response = await api.get(`${Endpoints.DRIVER}${toQueryString(data)}`);
    return response.data.data;
  }

  static async getByDriversId(id: number): Promise<ResDriverType> {
    const response = await api.get(`${Endpoints.DRIVER}/${id}`);
    return response.data.data;
  }

  static async editDrivers(
    id: number,
    data: Partial<SaveDriverType>
  ): Promise<any> {
    const response = await api.post(`${Endpoints.DRIVER}/${id}`, data);
    return response.data.data;
  }

  static async createDriver(data: Partial<SaveDriverType>) {
    const response = await api.post(`${Endpoints.DRIVER}`, data);
    return response.data;
  }
  static async deleteDriver(id: number) {
    const response = await api.post(`${Endpoints.DELETE_DRIVER}/${id}`);
    return response.data.data;
  }
  static async getDriverCode(): Promise<string> {
    const response = await api.get(Endpoints.GEN_CODE);
    return response.data;
  }
}
