import { Endpoints } from '../constants/constants'
import { sendFormData } from '../constants/helper'
import { ProfileType } from '../types/types'
import api from '../utils/api'
import apiWithoutToken from '../utils/apiWithoutToken'

type AuthData = {
    login: string
    password: string
}
export class AuthService {
    static async login(data: AuthData): Promise<ProfileType> {
        const response = await apiWithoutToken.post(Endpoints.LOGIN, data)

        return response.data.data
    }

    /**
     * get profile user
     */
    static async getUser(): Promise<any> {
        const response = await api.get(Endpoints.GET_USER)

        if (!response.data.success) {
            throw new Error('')
        }
        return response.data.data
    }
}