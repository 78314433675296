import { useState } from "react";
import { Input } from "../../../simples/Input";
import { ModalClose } from "../../../simples/ModalClose";
import styles from "./BallonsFilter.module.scss";
import { TextWithHintIcon } from "../../../simples/TextWithHintIcon";
import { Button } from "../../../simples/Button";

export type TReportFilter = {
  fio: string;
  agzs: string;
  numberAuto: string;
  numLitres: string;
  from?: string;
  to?: string;
};

type Props = {
  filters: Partial<TReportFilter>;
  onClose: () => void;
  onSubmit: (filter: Partial<TReportFilter>) => void;
};

const initialFilter: TReportFilter = {
  fio: "",
  agzs: "",
  numberAuto: "",
  numLitres: "",
};

const BallonsFilter = ({ filters, onClose, onSubmit }: Props) => {
  const [filter, setFilter] = useState<TReportFilter>({
    ...initialFilter,
    ...filters,
  });

  const onChangeFilter = (key: keyof TReportFilter, value: string) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = () => {
    onSubmit(filter);
    onClose();
  };

  return (
    <ModalClose
      title={"Фильтры"}
      onClose={onClose}
      classes={{ modal: styles.modal, body: styles.body }}
    >
      <TextWithHintIcon classes={{ root: styles.title }} title="ФИО" />
      <Input
        classes={{ input: styles.input }}
        onChange={onChangeFilter.bind("", "fio")}
        value={filter.fio}
        placeholder="Введите фио"
      />
      <TextWithHintIcon classes={{ root: styles.title }} title="АГЗС" />
      <Input
        classes={{ input: styles.input }}
        onChange={onChangeFilter.bind("", "agzs")}
        value={filter.agzs}
        placeholder="Введите название станции"
      />
      <TextWithHintIcon classes={{ root: styles.title }} title="Номер авто" />
      <Input
        classes={{ input: styles.input }}
        onChange={onChangeFilter.bind("", "numberAuto")}
        value={filter.numberAuto}
        placeholder="Введите номер автомобиля"
      />
      <TextWithHintIcon
        classes={{ root: styles.title }}
        title="Количество литров"
      />
      <Input
        classes={{ input: styles.input }}
        onChange={onChangeFilter.bind("", "numLitres")}
        value={filter.numLitres}
        placeholder="Введите количество литров"
      />
      <div className={styles.buttonContainer}>
        <Button className={styles.buttonCancel} onClick={onClose}>
          Отменить
        </Button>
        <Button className={styles.buttonSubmit} onClick={handleSubmit}>
          Применить
        </Button>
      </div>
    </ModalClose>
  );
};

export default BallonsFilter;
