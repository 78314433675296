import React, { FC, useEffect, useState } from "react";
import { Endpoints } from "../../../../../constants/constants";
import { thousand } from "../../../../../constants/helper";
import { PaymentOrderServices } from "../../../../../services/PaymentOrder";
import { AddButton } from "../../../../simples/AddButton";
import { BlockWrapper } from "../../../../simples/BlockWrapper";
import { Button } from "../../../../simples/Button";
import { Input } from "../../../../simples/Input";
import { TypeInput } from "../../../../simples/Input/Input";
import { ModalClose } from "../../../../simples/ModalClose";
import styles from "../../MainPage.module.scss";

type Props = {
  endDate: string;
  balance: number;
  datePay: number | string;
  alert: () => void;
};

const YourBalance: FC<Props> = ({ endDate, alert, balance, datePay }) => {
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [addBalance, setAddBalance] = useState<string>("");
  const removeModal = () => {
    setModalShow(false);
  };
  const openModal = () => {
    setModalShow(true);
  };

  const pay = async () => {
    try {
      const response = await PaymentOrderServices.Payment({
        sum: +addBalance,
      });
      if (response.success) {
        // const url = window.URL.createObjectURL(new Blob([response.fileName]));
        const link = document.createElement("a");
        link.href = `${response.data.fileName}`;
        link.setAttribute("download", "file.pdf"); //or any other extension
        link.setAttribute("target", "_blank"); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        alert();
      }

      removeModal();
    } catch (error) {
      console.log("PaymentOrderServices", error);
    }
  };
  return (
    <>
      <BlockWrapper isFull>
        <div className={styles.mainPageItemWrapper}>
          <h3 className={styles.title}>Ваш баланс</h3>
          <p className={styles.price}>{thousand(balance)} ₽</p>
          <p className={styles.remains}>
            С учетом последней оплаты от {endDate} на {thousand(datePay)} ₽
          </p>
          <div className={styles.button}>
            <AddButton onClick={openModal}>Пополнить баланс</AddButton>
          </div>
        </div>
      </BlockWrapper>
      {modalShow && (
        <ModalClose
          title="Пополнение баланса"
          onClose={removeModal}
          classes={{
            body: styles.modalRoot,
          }}
        >
          <Input
            typeProps={TypeInput.NUMBER}
            label="Сумма пополнения"
            placeholder="12000"
            onChange={setAddBalance}
            value={addBalance}
          />
          <Button onClick={pay} className={styles.buttonModal}>
            Получить счёт
          </Button>
        </ModalClose>
      )}
    </>
  );
};

export default YourBalance;
