import { Endpoints } from "../constants/constants";
import { toQueryString } from "../constants/helper";
import api from '../utils/api'

export type PaymentType = {
    success: boolean
    data: {
        fileName: string
    }
}
export class PaymentOrderServices {
    static async Payment(data: { sum: number }): Promise<PaymentType> {
        const response = await api.get(`${Endpoints.PAYMENT}${toQueryString(data)}`)

        return response.data

    }

    // http://88.198.56.78:8110/api/v1/legal/payment-order?sum=555


}