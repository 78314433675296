import { useState, useEffect } from "react";
import { ScreenWrapper } from "../../complexes/ScreenWrapper";
import UserFields from "../../complexes/UserFields/UserFields";
import { initialUserData } from "../../../constants/user";
import { CompanyData, ValidCompanyDataErrors } from "../../../types/user";
import { CompanyService } from "../../../services/CompanyService";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/store";
import { Routes } from "../../../constants/config";
import { setBlockedPage } from "../../../store/slice/app";

type LocalState = {
  isBlocked?: boolean;
};

function Profile() {
  const [errors, setErrors] = useState<ValidCompanyDataErrors>({});
  const [isLoading, setIsLoading] = useState(false);
  const [initalProfile, setInitialProfile] = useState(initialUserData);
  const state = useLocation().state as LocalState;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const edditProfile = async (profileData: CompanyData) => {
    setIsLoading(true)
    const data = await CompanyService.updateCompanyData(profileData);
    data?.errors && setErrors(data.errors);
    state?.isBlocked && !data.errors && navigate(Routes.HOME);
    !data.errors && dispatch(setBlockedPage(null));
    setIsLoading(false)

    return !data.errors;
  };

  useEffect(() => {
    (async () => {
      const profileData = await CompanyService.getCompanyData();
      setInitialProfile(profileData);
    })();
  }, []);

  useEffect(() => {
    state?.isBlocked && dispatch(setBlockedPage(Routes.PROFILE));
  }, []);

  return (
    <ScreenWrapper isLoading={isLoading} title={"Профиль"}>
      <UserFields
        fromProfile={true}
        backError={errors}
        buttonText={["Редактировать", "Сохранить"]}
        onSubmit={edditProfile}
        initialState={initalProfile}
      />
    </ScreenWrapper>
  );
}

export default Profile;
