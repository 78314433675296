import React, { FC, useCallback, useState } from "react";
import styles from "./Auth.module.scss";
import { ReactComponent as GgAuthBig } from "../../../assets/images/ggAuthBig.svg";
import { useAppDispatch } from "../../../hooks/store";
import { getUser, signIn } from "../../../store/slice/auth";
import { Button } from "../../simples/Button";
import { CheckBox } from "../../simples/CheckBox";
import { Input } from "../../simples/Input";
import { TypeInput } from "../../simples/Input/Input";
import { TextLine } from "../../simples/TextLine";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../constants/config";
import { Helmet } from "react-helmet";

// import { AuthService } from '../../../services/AuthService'

const Auth: FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [remember, setRemember] = useState<boolean>(true);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState<{
    login?: string;
    password?: string;
  }>({});

  const logIn = useCallback(async () => {
    if (!password) {
      setErrors((prev) => ({
        ...prev,
        password: "Поле обязательно для заполнения",
      }));
    }
    if (!email) {
      setErrors((prev) => ({
        ...prev,
        login: "Поле обязательно для заполнения",
      }));
    }

    if (password.length && email.length) {
      try {
        const response = await dispatch(
          signIn({
            login: email,
            password,
            remember,
          })
        );

        if (response.payload === undefined) {
          setErrors((prev) => ({
            ...prev,
            login: "Неправильный логин или пароль",
          }));
        } else {
          await dispatch(getUser());
        }
      } catch (error: any) {
        setErrors((prev) => ({
          ...prev,
          login: error.response.data.errors[0],
        }));
      }
    }
  }, [dispatch, email, password, remember]);

  const passwordEnter = (str: string) => {
    setErrors((prev) => ({ ...prev, password: "" }));
    setPassword(str);
  };
  const loginEnter = (str: string) => {
    setErrors((prev) => ({ ...prev, login: "" }));
    setEmail(str);
  };
  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    logIn();
  };
  const goToRegistration = () =>
    navigate(Routes.REGISTRETION, { state: { isTypeInn: true } });

  return (
    <div className={styles.root}>
      <Helmet>
        <title>Авторизация</title>
        <link rel="canonical" href="https://admin.ggroup-ural.ru/auth" />
      </Helmet>
      <div className={styles.centerBlock}>
        <div className={styles.centerInnerBlock}>
          <div className={styles.border}>
            <div className={styles.innerLeft}>
              <div>
                <div className={styles.leftTopText}>
                  <TextLine color={"#A2A3A5"} weight={600}>
                    Добро пожаловать в{" "}
                  </TextLine>
                </div>
                <div className={styles.leftTopText}>
                  <TextLine size={32} weight={600}>
                    GGroup
                  </TextLine>
                </div>
              </div>
              <div>
                <GgAuthBig />
              </div>
            </div>
            <form onSubmit={submitForm} className={styles.innerRight}>
              <div className={styles.authText}>
                <TextLine weight={600}>Авторизация</TextLine>
              </div>
              <div>
                <div className={styles.inputHolder}>
                  <Input
                    error={errors.login}
                    placeholder={"Логин"}
                    value={email}
                    onChange={loginEnter}
                  />
                </div>
                <div className={styles.inputHolderPass}>
                  <Input
                    typeProps={TypeInput.PASSWORD}
                    error={errors.password}
                    placeholder={"Пароль"}
                    value={password}
                    onChange={passwordEnter}
                  />
                </div>

                <div className={styles.helpBlock}>
                  <TextLine
                    color="rgba(19, 26, 47, 0.7)"
                    weight={500}
                    size={12}
                  >
                    Забыли пароль?
                  </TextLine>
                  <TextLine
                    color="rgba(19, 26, 47, 0.7)"
                    weight={500}
                    size={12}
                  >
                    Обратитесь по номеру
                    <span className={styles.redText}> +7 (343) 385-80-60</span>
                  </TextLine>
                </div>

                <div className={styles.checkBox}>
                  <CheckBox
                    checked={remember}
                    onChange={() => setRemember((prev) => !prev)}
                  />
                  <TextLine family="Roboto" color={"rgba(0, 0, 0, 0.5)"}>
                    Запомнить меня
                  </TextLine>
                </div>
              </div>
              <div className={styles.buttonsContainer}>
                <Button className={styles.enterButton} type={"submit"}>
                  Войти
                </Button>
                <Button
                  className={styles.registerButton}
                  onClick={goToRegistration}
                >
                  Регистрация
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
