import classNames from "classnames";
import { FC, forwardRef } from "react";
import styles from "./Textarea.module.scss";

export type TextareaProps = {
  onChange: (e: string) => void;
  value: string | number;
  disabled?: boolean;
  placeholder?: string;
  classes?: {
    input?: string;
  };
  rows?: number;
  cols?: number;
  error?: string | undefined;
  label?: string;
};

const Textarea: FC<TextareaProps> = forwardRef<
  HTMLTextAreaElement,
  TextareaProps
>(
  (
    { onChange, value, disabled, placeholder, classes, label, error, rows },
    ref
  ) => {
    const setInput = (ev: React.ChangeEvent<HTMLTextAreaElement>) =>
      onChange(ev.target.value);

    return (
      <div className={styles.root}>
        {label && <div className={styles.label}>{label}</div>}

        <div
          className={classNames(styles.inputWrapper, {
            [styles.error]: !!error,
          })}
        >
          <div style={{ width: "100%" }}>
            <textarea
              ref={ref}
              disabled={disabled}
              className={classNames(
                styles.input,
                {
                  [styles.disabled]: disabled,
                },
                classes?.input,
              )}
              placeholder={placeholder}
              onChange={setInput}
              value={value}
              rows={rows || 3}
            />
          </div>
        </div>
        {error && <div className={styles.errorText}>{error}</div>}
      </div>
    );
  }
);

export default Textarea;
