import classNames from 'classnames'
import React, { FC } from 'react'
import { thousand } from '../../../../../constants/helper'
import { TransType } from '../../../../../types/types'
import styles from './HistoryTabel.module.scss'
type Props = {
    lineItem: TransType
}

const HistoryTabel: FC<Props> = ({ lineItem }) => {
    return (
        <div className={styles.line}>
            <div className={styles.lineItem} >
                <p>{lineItem.created_at.split(' ')[0].split('-').reverse().join('.')}</p>
                <p>{lineItem.created_at.split(' ')[1].split(':')[0] + ':' + lineItem.created_at.split(' ')[1].split(':')[1]}</p>
            </div>
            <div className={styles.lineItem} >{lineItem.income == 0 ? '-' : `${thousand(lineItem.income)} ₽`} </div>
            <div className={styles.lineItem} >{lineItem.spend == 0 ? '-' : `${thousand(lineItem.spend)} ₽`} </div>
            <div className={classNames(styles.lineItem, styles.lineItemEnd)} >
                <p>{lineItem.driver.fio}</p>
            </div>
        </div>
    )
}

export default HistoryTabel