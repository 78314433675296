import styles from "./RadioButton.module.scss";
import RadioIcon from "../../SVGR/Radio";
import { TextLine } from "../TextLine";
import classNames from "classnames";
type Props = {
  isChoosed: boolean;
  onClick: () => void;
  title?: string;
  classes?: {
    root?: string;
  };
};

const RadioButton = ({ isChoosed, onClick, title, classes }: Props) => (
  <button
    className={classNames(styles.container, classes?.root)}
    onClick={onClick}
  >
    <RadioIcon isChoosed={isChoosed} />
    {title && <TextLine ml={8}>{title}</TextLine>}
  </button>
);

export default RadioButton;
