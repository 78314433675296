import { createSlice } from "@reduxjs/toolkit";
import { Routes } from "../../constants/config";
import { RootState } from "../index";

type InitialState = {
  isLoading: boolean;
  blockedPage: Routes | null;
};

export const initialState: InitialState = {
  isLoading: false,
  blockedPage: null,
};

// /**
//  * Создание слайса App
//  */
export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setBlockedPage: (state, action) => {
      state.blockedPage = action.payload;
    },
  },
});

export const { setIsLoading, setBlockedPage } = appSlice.actions;

export const selectIsLoading = (state: RootState) => state.app.isLoading;
// -------------------------------------------
// Export a reducer
// -------------------------------------------
export default appSlice.reducer;
