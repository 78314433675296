import React, { FC, useState } from 'react'
import { thousand } from '../../../../../constants/helper'
import { AddButton } from '../../../../simples/AddButton'
import { BlockWrapper } from '../../../../simples/BlockWrapper'
import { Button } from '../../../../simples/Button'
import { Input } from '../../../../simples/Input'
import { ModalClose } from '../../../../simples/ModalClose'
import styles from '../../MainPage.module.scss'

type Props = {
    remains: number
    balance: number
}

const OverDraft: FC<Props> = ({ balance, remains, }) => {

    return (
        <>
            <BlockWrapper isFull>
                <div className={styles.mainPageItemWrapper}>
                    <h3 className={styles.title}>Овердрафт</h3>
                    <p className={styles.price}>-{thousand(balance)}{' '}₽</p>
                    <p className={styles.remains}>Осталось {thousand(remains)}{' '}₽</p>
                    <p className={styles.notification}>
                        Допустимая задолженность
                    </p>
                </div>
            </BlockWrapper>
        </>
    )
}

export default OverDraft