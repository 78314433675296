import "../../../styles/ReactToastifyMy.scss";
import styles from "./MainPage.module.scss";
import { FC, useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../hooks/store";
import { TransactionsServices } from "../../../services/Transactions";
import { RootShortTransType, TransShortType } from "../../../types/types";
import { ScreenWrapper } from "../../complexes/ScreenWrapper";
import { BottomSummaryTable } from "./parts/BottomSummaryTable";
import { OverDraft } from "./parts/OverDraft";
import { YourBalance } from "./parts/YourBalance";
import { ModalBase } from "../../simples/ModalBase";
import { logout } from "../../../store/slice/auth";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../constants/config";
import PersonalBlock from "./parts/PersonalBlock/PersonalBlock";

const MainPage: FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [modal, setModal] = useState(false);
  const [transData, setTransData] = useState<TransShortType[]>([]);
  const allData = useRef<TransShortType[]>([]);
  allData.current = transData;
  const [Trans, setTrans] = useState<RootShortTransType>();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const has = useRef(true);
  const offset = useRef(0);

  const dateApply = async (date: string) => {
    clear(date);
  };

  const clear = async (da?: string) => {
    setTransData([]);
    allData.current = [];
    has.current = true;
    setLoading(false);
    offset.current = 0;
    await fetchTrans(da);
  };

  const fetchTrans = async (dateLocal?: string) => {
    setLoading(true);

    if (!has.current || loading) return;

    const response = await TransactionsServices.lazyTransactions({
      limit: 5,
      offset: offset.current,
      start: dateLocal ? dateLocal.split(" - ")[0] : undefined,
      end: dateLocal ? dateLocal.split(" - ")[1] : undefined,
    });

    if (!response.data.length) {
      has.current = false;
      return;
    }
    offset.current = offset.current + response.data.length;

    setTransData([...allData.current, ...response.data]);
    setTrans(response);
    setLoading(false);
  };

  const onPressNegativeModal = () => {
    dispatch(logout());
    window.location.reload();
  };

  const onAgreeModal = () => {
    navigate(Routes.PROFILE, { state: { isBlocked: true } });
  };

  useEffect(() => {
    fetchTrans();
  }, []);

  useEffect(() => {
    user && !user.type_of_ownership && setModal(true);
  }, [user]);

  const alert = () => {
    toast.error(
      "Не указан договор. Обратитесь в GGROUP по тел. +7 (343) 385-80-60",
      {
        position: "top-center",
        autoClose: 2000,
        pauseOnHover: true,
        draggable: false,
        hideProgressBar: true,
        progress: undefined,
      }
    );
  };

  return (
    <>
      <div style={{ flex: "1" }}>
        <div style={{ overflowY: "auto", maxHeight: "100%" }}>
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar={true}
            newestOnTop={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            closeOnClick={true}
            pauseOnHover
          />
          <ScreenWrapper title="Главная">
            <div className={styles.mainPageTop}>
              <div className={styles.mainPageItem}>
                {user && (
                  <YourBalance
                    alert={alert}
                    endDate={
                      user?.lastIncomeTransaction?.created_at
                        ?.split(" ")[0]
                        ?.split("-")
                        ?.reverse()
                        ?.join(".") || ""
                    }
                    balance={user?.balance || 0}
                    datePay={user?.lastIncomeTransaction?.price || ""}
                  />
                )}
              </div>
              <PersonalBlock />

              {user?.overdraft && (
                <div className={styles.mainPageItem}>
                  <OverDraft
                    balance={user?.overdraft ? user?.overdraft : 0}
                    remains={
                      user?.balance
                        ? (user?.overdraft < 0
                            ? user?.overdraft * -1
                            : user?.overdraft) + user?.balance
                        : 0
                    }
                  />
                </div>
              )}
            </div>
            <div className={styles.mainPageBottom}>
              <BottomSummaryTable
                totalLiters={Trans?.totalLitres || 0}
                totalSpend={Trans?.totalSpend || 0}
                onFilter={dateApply}
                scrollReach={fetchTrans}
                list={transData}
              />
            </div>
          </ScreenWrapper>
        </div>
      </div>
      {modal && (
        <ModalBase
          onClickYes={onAgreeModal}
          onClickNo={onPressNegativeModal}
          title={
            "Для продолжения работы с личным кабинетом, необходимо заполнить профиль, желаете продолжить сейчас?"
          }
        />
      )}
    </>
  );
};

export default MainPage;
