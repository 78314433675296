import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "../../../../../constants/config";
import { Button } from "../../../../simples/Button";
import { Input } from "../../../../simples/Input";
import { RadioButton } from "../../../../simples/RadioButton";
import { TextWithHintIcon } from "../../../../simples/TextWithHintIcon";
import styles from "./RegistationTypeInn.module.scss";

type LocationState = {
  inn?: string;
  vat?: boolean;
};

const RegistationTypeInn = () => {
  const [inn, setInn] = useState<string>("");
  const [vat, setVat] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as LocationState;


  const goToNextStep = () =>
    navigate(Routes.REGISTRETION, {
      state: { inn, vat },
    });

  // set inital states
  useEffect(() => {
    locationState.inn && setInn(locationState.inn);
    locationState.vat && setVat(locationState.vat);
  }, [locationState]);

  const isGoodValid = !(inn.length === 12 || inn.length === 10);
  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <TextWithHintIcon classes={{ root: styles.topTitle }} title="ИНН" />
        <Input classes={{ input: styles.input }} onChange={setInn} value={inn} mask={"999999999999"} />
        <TextWithHintIcon
          classes={{ root: styles.orgName }}
          title="Форма организации"
        />
        <RadioButton
          isChoosed={vat}
          title="С НДС"
          onClick={setVat.bind(null, true)}
        />
        <RadioButton
          classes={{ root: styles.bottomRadio }}
          isChoosed={!vat}
          title="БЕЗ НДС"
          onClick={setVat.bind(null, false)}
        />
      </div>
      <div className={styles.button}>
        <Button onClick={goToNextStep} disabled={isGoodValid}>
          Зарегистрироваться
        </Button>
      </div>
    </div>
  );
};

export default RegistationTypeInn;
