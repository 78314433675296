import classNames from "classnames";
import { ReactComponent as TrashIcon } from "../../../assets/icons/boldTrash.svg";
import styles from "./DeleteButton.module.scss";

type Props = {
  title: string;
  onClick: () => void;
  className?: string;
};

const DeleteButton = ({ title, onClick, className }: Props) => (
  <button onClick={onClick} className={classNames(styles.container, className)}>
    <TrashIcon className={styles.trashIcon} />
    {title}
  </button>
);

export default DeleteButton;
