import React, { FC, MouseEventHandler } from "react";
import styles from "./ButtonWithSvg.module.scss";
import classNames from "classnames";

type IconTypeProps = {
  width: number;
  height: number;
  color: string;
};

type IconType = (props: IconTypeProps) => JSX.Element;

type Props = {
  icon: IconType;
  onClick: MouseEventHandler<HTMLDivElement>;
  classes?: {
    root?: string;
  };
};

const ButtonWithSvg: FC<Props> = ({ classes, onClick, icon }) => {
  return (
    <div
      onClick={onClick}
      className={classNames(styles.wrapperBlock, classes?.root)}
    >
      {React.createElement(icon, {
        width: 18,
        height: 18,
        color: "rgba(41, 45, 50, 1)",
      })}{" "}
    </div>
  );
};

export default ButtonWithSvg;
