import styles from "../UserFields.module.scss";
import { CompanyData, ValidCompanyDataErrors } from "../../../../types/user";
import { Input } from "../../../simples/Input";
import { TextWithHintIcon } from "../../../simples/TextWithHintIcon";
import { Textarea } from "../../../simples/Textarea";
import { TypeInput } from "../../../simples/Input/Input";

type Props = {
  data: CompanyData;
  setData: (field: keyof CompanyData, value: string) => void;
  errors: ValidCompanyDataErrors | undefined;
  isBeenChange?: boolean;
};

const RightPartLegalUserFields = ({
  data,
  setData,
  errors,
  isBeenChange = true,
}: Props) => (
  <div className={styles.topSiteContainer}>
    <TextWithHintIcon
      classes={{ root: styles.topTitle }}
      title={"Краткое наименование"}
    />
    <Input
      error={errors?.["name-organization"] && errors["name-organization"][0]}
      classes={{ input: styles.input }}
      onChange={setData.bind(null, "name-organization")}
      value={data["name-organization"]}
      placeholder="Введите краткое наименование"
      disabled={!isBeenChange}
    />

    <TextWithHintIcon
      classes={{ root: styles.title }}
      title="Полное наименование*"
    />

    <Textarea
      error={errors?.["full-name"] && errors["full-name"][0]}
      value={data["full-name"]}
      onChange={setData.bind(null, "full-name")}
      rows={3}
      classes={{ input: styles.textArea }}
      disabled={!isBeenChange}
      placeholder="Введите полное наименование"
    />

    <TextWithHintIcon
      classes={{ root: styles.title }}
      title="ФИО генерального директора"
    />
    <Input
      error={errors?.["general-director"] && errors["general-director"][0]}
      classes={{ input: styles.input }}
      onChange={setData.bind(null, "general-director")}
      value={data["general-director"]}
      disabled={!isBeenChange}
      placeholder="Введите фио"
    />

    <TextWithHintIcon classes={{ root: styles.title }} title="ОГРН" />
    <Input
      ml={15}
      typeProps={TypeInput.NUMBER}
      error={errors?.["ogrn"] && errors["ogrn"][0]}
      classes={{ input: styles.input }}
      onChange={setData.bind(null, "ogrn")}
      value={data["ogrn"]}
      disabled={!isBeenChange}
      placeholder="Введите номер"
    />

    <TextWithHintIcon classes={{ root: styles.title }} title="КПП" />
    <Input
      ml={9}
      typeProps={TypeInput.NUMBER}
      error={errors?.["kpp"] && errors["kpp"][0]}
      classes={{ input: styles.input }}
      onChange={setData.bind(null, "kpp")}
      value={data["kpp"]}
      disabled={!isBeenChange}
      placeholder="Введите код"
    />
    <TextWithHintIcon classes={{ root: styles.title }} title="ОКВЭД" />
    <Input
      ml={9}
      error={errors?.["okveds"] && errors["okveds"][0]}
      classes={{ input: styles.input }}
      onChange={setData.bind(null, "okveds")}
      value={data["okveds"]}
      disabled={!isBeenChange}
      placeholder="Введите значение"
    />
  </div>
);

export default RightPartLegalUserFields;
