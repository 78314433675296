import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import styles from './ElementWrapper.module.scss';

type Props = {
  children: ReactNode
  pG?: number
  pV?: number
  className?: string
  onClick?: any

}

const ElementWrapper: FC<Props> = ({
  pG = 2, pV = 2, className, children, onClick,
}) => (
  <div onClick={onClick} className={classNames(styles.root, className)} style={{ padding: `${pV}px ${pG}px` }}>{children}</div>
);

export default ElementWrapper;
