import { Endpoints } from "../constants/constants";
import { toQueryString } from "../constants/helper";
import { lazyLoadingType, Station } from "../types/types";
import api from '../utils/api'

export type StationsFetchType = lazyLoadingType & {
    exclude: number[]
}
export class Stations {
    static async getStations(data?: StationsFetchType): Promise<Station[]> {
        const response = await api.get(`${Endpoints.STATIONS}${toQueryString(!!data && data)}`)
        return response.data.data
    }
}