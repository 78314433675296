import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Drivers.module.scss";
import { ScreenWrapper } from "../../complexes/ScreenWrapper";
import { ScrollBlock } from "../../simples/ScrollBlock";
import { Search } from "../../simples/Search";
import DriversCard from "./parts/Card/DriversCard";
import { Routes } from "../../../constants/config";
import { Drivers as driversServices } from "../../../services/Drivers";
import { DriversType } from "../../../types/types";
import { useAppSelector } from "../../../hooks/store";
import { Button } from "../../simples/Button";
import { NUMBER_AS_LOGING } from "../../../constants/hints";

function Drivers() {
  const [drivers, setDrivers] = useState<DriversType[]>([]);
  const allData = useRef<DriversType[]>([]);
  allData.current = drivers;

  const [search, setSearch] = useState<string>();
  const searchRef = useRef(search);
  searchRef.current = search;
  const has = useRef(true);
  const offset = useRef(0);
  const navigate = useNavigate();
  const isLoading = useAppSelector((state) => state.app.isLoading);

  const clearDrivers = async () => {
    offset.current = 0;
    has.current = true;
    setDrivers([]);
    allData.current = [];
    await getDrivers();
  };


  const getDrivers = useCallback(async () => {
    if (!has.current) return;
    try {
      const response = await driversServices.getDrivers({
        offset: offset.current,
        limit: 15,
        search: searchRef.current,
      });

      if (!response.length) {
        has.current = false;
        return;
      }

      const newUnique = response.filter(el => (allData.current.findIndex(elAllData => elAllData.id === el.id) === -1))
      offset.current += newUnique.length;
      setDrivers([...allData.current, ...newUnique]);
    } catch (error) {
      console.error("erroe drives ", error);
    }
  }, []);

  /**
   * @param {number} editUser  { id }ч accepts id for user
   */
  const editUser = (id: number) => {
    navigate(`${Routes.DRIVERS_EDIT}/${id}`);
  };

  const goToCreateDriver = () => {
    navigate(Routes.DRIVERS_CREATE);
  };

  const excludeRemovedDriver = (id: number) => {
    setDrivers((prev) => prev.filter((driver) => driver.id !== id));
  };

  useEffect(() => {
    const time = setTimeout(() => {
      clearDrivers();
    }, 500);

    return () => {
      clearTimeout(time);
    };
  }, [search]);

  useEffect(() => {
    getDrivers();
    return () => {
      clearDrivers();
    };
  }, []);

  return (
    <ScreenWrapper
      isLoading={isLoading}
      title="Водители"
      topLeftSide={
        <span className={styles.headerContainer}>
          <Search
            onChange={(data) => setSearch(data)}
            classes={{
              block: styles.settingSearch,
            }}
            placeholder="Введите данные водителя"
            value={search}
          />
          <Button
            onClick={goToCreateDriver}
            className={styles.createDriverButton}
          >
            <p className={styles.createDriverText}>Создать водителя</p>
            <p className={styles.plusText}>+</p>
          </Button>
        </span>
      }
      topRightSide={<p className={styles.hintText}>{NUMBER_AS_LOGING}</p>}
    >
      <div className={styles.header}>
        <div className={styles.cell}>Номер карты</div>
        <div className={styles.cell}>ФИО</div>
        <div className={styles.cell}>Номер телефона</div>
        <div className={styles.cellEnd}>Автомобиль</div>
        <div className={styles.cellEnd}>Лимит литров</div>
      </div>
      <ScrollBlock onReachedEnd={getDrivers}>
        <table className={styles.table}>
          <tbody className={styles.tableBody}>
            {drivers.map((driver) => (
              <DriversCard
                remove={excludeRemovedDriver}
                onEdit={(id: number) => editUser(id)}
                key={driver.id}
                cardInfo={driver}
              />
            ))}
          </tbody>
        </table>
      </ScrollBlock>
    </ScreenWrapper>
  );
}

export default Drivers;
