import React from "react";
import { TextLine } from "../TextLine";

type Props = {
  children: React.ReactNode;
  mt?: number;
};

const HintText = ({ children, mt }: Props) => (
  <TextLine weight={500} mt={mt} color="#76227F">
    {children}
  </TextLine>
);

export default HintText;
