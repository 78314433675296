import classNames from 'classnames';
import React, { FC } from 'react';
import { RouteProps } from 'react-router-dom';
import styles from './ItemHolder.module.scss';

type Props = RouteProps & {
  mb?: number
  flex?: boolean
}

const ItemHolder: FC<Props> = ({ flex = false, mb = 30, children }) => (
  <div
    style={{
      marginBottom: mb,
    }}
    className={classNames(styles.root, { [styles.flex]: flex })}
  >
    {children}
  </div>
);
export default React.memo(ItemHolder);
