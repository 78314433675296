import React, { FC } from 'react';
import { RouteProps } from 'react-router-dom';
import styles from './AddButton.module.scss';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { Button, ButtonColors } from '../Button';

type Props = RouteProps & {
    onClick: () => void
    // width?: number
}

const AddButton: FC<Props> = ({ onClick, children }) => (
  <Button
    className={styles.addBtn}
    onClick={onClick}
    color={ButtonColors.Secondary}
  >
    <PlusIcon />
    <span className={styles.addBtnTaxt}>
      {children}
    </span>
  </Button>
);
export default React.memo(AddButton);
