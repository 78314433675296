import classNames from "classnames";
import styles from "./TextWithHintIcon.module.scss";
import { ReactComponent as HintIcon } from "../../../assets/icons/HintIcon.svg";

type Props = {
  title: string;
  classes?: {
    root?: string;
    title?: string;
  };
  withHint?: boolean;
};

const TextWithHintIcon = ({ title, classes, withHint }: Props) => (
  <span className={classNames(styles.container, classes?.root)}>
    <h4 className={classNames(styles.title, classes?.title)}>{title}</h4>
    {withHint && <HintIcon />}
  </span>
);

export default TextWithHintIcon;
