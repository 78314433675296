import classNames from "classnames";
import React, { FC, useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { MenuItemType } from "../../Layout";
import styles from "./MenuItem.module.scss";

type Props = {
  data: MenuItemType;
  isFocused?: boolean;
  isBlocked?: boolean;
};

const MenuItem: FC<Props> = ({ data, isFocused, isBlocked }) => {
  const route: string = useLocation().pathname;
  const isSameRoute: boolean = useMemo(
    () => route.includes(data.route),
    [data.route, route]
  );
  const isActive = isSameRoute || isFocused;

  return (
    <NavLink
      className={classNames(
        isActive ? styles.activeLink : styles.link,
        isBlocked ? styles.pointerNone : ""
      )}
      to={data.route}
    >
      <div
        style={{
          opacity: isActive ? 1 : 0.5,
        }}
        className={classNames(styles.root, {
          [styles.activeMenu]: isActive,
        })}
      >
        <div
          className={classNames(styles.icon, {
            [styles.activeIcon]: isActive,
          })}
        >
          {data.icon}
        </div>
        <div className={styles.text}>{data.label}</div>
      </div>
    </NavLink>
  );
};
export default React.memo(MenuItem);
