import { getOnlyNumbers } from "./../constants/composeString";
import { CompanyData, ValidCompanyDataErrors } from "./../types/user";
import { Endpoints } from "../constants/constants";
import api from "../utils/api";
import { initialUserData, UserTypes, VatTypes } from "../constants/user";
import { ProfileType } from "../types/types";

type UpdateUserData = {
  data: CompanyData | [];
  errors: (ValidCompanyDataErrors & ErrorType) | null;
};

type ErrorType = {
  status?: string;
  message?: string;
};

export class CompanyService {
  static async getCompanyData(): Promise<CompanyData> {
    let result = initialUserData;
    const response = await api.get<{ success: boolean; data: ProfileType }>(
      Endpoints.GET_USER
    );
    if (response.data.success) {
      const data = response.data.data;
      result = {
        inn: data.inn || "",
        phone: data.phone || "",
        email: data.email || "",
        "full-name": data["full_name"] || "",
        ogrn: data.ogrn || "",
        kpp: data.kpp || "",
        "name-organization": data.companyName || "",
        "form-of-organization": data.form_of_organization || VatTypes.withVat,
        "type-of-ownership": data.type_of_ownership || UserTypes.individual,
        "general-director": data.genDir || "",
        "surname-ip": data.surname_ip || "",
        "name-ip": data.name_ip || "",
        "patronymic-ip": data.patronymic_ip || "",
        okveds: data.okveds || "",
      };
    }
    return result;
  }

  static async updateCompanyData(data: CompanyData): Promise<UpdateUserData> {
    const result: UpdateUserData = {
      data: [],
      errors: null,
    };
    const response = await api.post(Endpoints.UPDATE_PROFILE, {
      ...data,
      phone: getOnlyNumbers(data.phone || ""),
    });
    const resData = response.data;
    result.data = resData.data;
    result.errors = resData.success ? null : resData.errors;
    return result;
  }
}
