import {
  Route,
  BrowserRouter as Router,
  Routes as RoutesDom,
  Navigate,
} from "react-router-dom";
import { useEffect } from "react";
import { Routes } from "../../constants/config";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import {
  getUser,
  selectAuthChecking,
  selectIsAuth,
} from "../../store/slice/auth";
import { Layout } from "../complexes/Layout";
import { Drivers } from "../page/Drivers";
import { DriversEdit } from "../page/DriversEdit";
import { History } from "../page/History";
import { MainPage } from "../page/MainPage";
import { Profile } from "../page/Profile";
import styles from "./App.module.scss";
import { Auth } from "../page/Auth";
import { MainPreloader } from "../simples/MainPreloader";
import { Registration } from "../page/Registration";
import { DriverCreate } from "../page/DriverCreate";
import Report from "../page/Report/Report";
import Map from "../page/Map/Map";
import ReportBallons from "../page/ReportBallons/Ballons";

function App() {
  const preloading = useAppSelector(selectAuthChecking);

  const isAuth = useAppSelector(selectIsAuth);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const isRemember = localStorage.getItem("remember");
    if (isRemember === "yes") {
      console.log("");
    } else {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("remeber");
    }

    dispatch(getUser()).then();
  }, []);

  if (preloading) {
    return <MainPreloader />;
  }
  return (
    <div className={styles.app}>
      <Router>
        {!isAuth ? (
          <RoutesDom>
            <Route path="*" element={<Navigate replace to={Routes.AUTH} />} />
            <Route path={Routes.AUTH} element={<Auth />} />
            <Route path={Routes.REGISTRETION} element={<Registration />} />
          </RoutesDom>
        ) : (
          <Layout>
            <RoutesDom>
              <Route path={Routes.HOME} element={<MainPage />} />
              <Route path={Routes.REPORT} element={<Report />} />
              <Route path={Routes.BALLONS} element={<ReportBallons />} />
              <Route path={Routes.DRIVERS} element={<Drivers />} />
              <Route path={Routes.DRIVERS_CREATE} element={<DriverCreate />} />
              <Route
                path={`${Routes.DRIVERS_EDIT}/:id`}
                element={<DriversEdit />}
              />
              <Route path={Routes.HISTORY} element={<History />} />
              <Route path={Routes.PROFILE} element={<Profile />} />
              <Route path={Routes.MAP} element={<Map />} />
              <Route path="*" element={<Navigate replace to={Routes.HOME} />} />
            </RoutesDom>
          </Layout>
        )}
      </Router>
    </div>
  );
}

export default App;
