import { FC, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { getSendDate } from "../../../constants/helper";
import ru from "date-fns/locale/ru";
import styles from "./DatePickerBlock.module.scss";

import "../../../styles/datePicker.scss";

type Props = {
  isPeriod: 1 | 0;
  onChange: (dates: Array<string>) => void;
  values: Array<string>;
};

const DatePickerBlock: FC<Props> = ({ isPeriod, onChange, values }) => {
  const [dateRange, setDateRange] = useState<[any, any]>([null, null]);

  const [startDate, endDate] = dateRange;

  const getDateFromRange = (startDate: any, endDate: any) => {
    const date = new Date(startDate.getTime());

    const dates: string[] = [];

    while (date <= endDate) {
      dates.push(getSendDate(new Date(date)));
      date.setDate(date.getDate() + 1);
    }

    onChange(dates);
  };

  const getDay = (date: any) => {
    const dayNumber = new Date(date).getDay();
    return dayNumber === 6 || dayNumber === 0 ? "weekend" : "workday";
  };

  useEffect(() => {
    if (startDate && endDate) {
      getDateFromRange(startDate, endDate);
    }
  }, [endDate, startDate]);

  useEffect(() => {
    if (isPeriod) {
      const sDate: string = values[0];
      const eDate: string = values[values.length - 1];
      setDateRange([new Date(sDate), new Date(eDate)]);
    }
    //  else {

    // }
  }, [isPeriod]);

  const onChangeDate = (date: Date) => {
    if (values.includes(getSendDate(date))) {
      onChange(values.filter((i) => i !== getSendDate(date)));
    } else {
      onChange([...values, getSendDate(date)]);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.calendarBlock}>
        {isPeriod ? (
          <DatePicker
            inline
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={(update: any) => {
              setDateRange(update);
            }}
            dateFormat="dd-MM-yyyy"
            isClearable
            locale={ru}
            dayClassName={getDay}
          />
        ) : (
          <DatePicker
            inline
            selected={
              values && values.length > 0
                ? new Date(values[values.length - 1])
                : new Date()
            }
            onChange={onChangeDate}
            highlightDates={values.map((i) => new Date(i))}
            locale={ru}
          />
        )}
      </div>
    </div>
  );
};
export default DatePickerBlock;
