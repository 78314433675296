import { FC, useState } from "react";
import classNames from "classnames";
import styles from "./DriversCard.module.scss";
import { ReactComponent as Trash } from "../../../../../assets/icons/trash.svg";
import { DriversType } from "../../../../../types/types";
import { Drivers } from "../../../../../services/Drivers";
import { useAppDispatch } from "../../../../../hooks/store";
import { setIsLoading } from "../../../../../store/slice/app";
import { ModalBase } from "../../../../simples/ModalBase";

type Props = {
  cardInfo: DriversType;
  onEdit: (id: number) => void;
  remove: (id: number) => void;
};

const StaffCard: FC<Props> = ({ cardInfo, onEdit, remove }) => {
  const driverInfo = cardInfo.driverProfile;
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState(false);

  const onRemove = async () => {
    setModal(false);
    dispatch(setIsLoading(true));
    Drivers.deleteDriver(cardInfo.id)
      .then(() => {
        remove(cardInfo.id);
        dispatch(setIsLoading(false));
      })
      .catch((err) => {
        console.log(
          "🚀 ~ file: DriversCard.tsx:27 ~ Drivers.deleteDriver ~ err:",
          err
        );
        dispatch(setIsLoading(false));
      });
  };

  return (
    <tr className={styles.cardInfoWrapper}>
      <td
        onClick={onEdit.bind(null, cardInfo.id)}
        style={{
          width: "15%",
        }}
        className={styles.cell}
      >
        {cardInfo?.codeAuto}
      </td>
      <td
        onClick={onEdit.bind(null, cardInfo.id)}
        style={{
          width: "21%",
        }}
        className={styles.cell}
      >
        <p> {`${cardInfo?.driverProfile.fio}`} </p>
      </td>
      <td
        onClick={onEdit.bind(null, cardInfo.id)}
        style={{
          width: "22%",
        }}
        className={styles.cell}
      >
        {cardInfo?.driverProfile.phone}
      </td>
      <td
        onClick={onEdit.bind(null, cardInfo.id)}
        style={{
          width: "13%",
        }}
        className={styles.cellEnd}
      >
        {`${
          cardInfo?.driverProfile.autoMark
        } ${cardInfo?.driverProfile?.autoNum?.toLocaleUpperCase()} `}
      </td>
      <td
        onClick={onEdit.bind(null, cardInfo.id)}
        style={{
          width: "17%",
        }}
        className={styles.cellEnd}
      >
        <>
          {driverInfo.limitLitresMonth === 0 && driverInfo.limitLitresDay === 0
            ? `Без ограничений`
            : `${
                driverInfo.limitLitresMonth || 0 > 0
                  ? driverInfo.litresMonth
                  : driverInfo.litresToday
              }/${
                driverInfo.limitLitresMonth || 0 > 0
                  ? driverInfo.limitLitresMonth
                  : driverInfo.limitLitresDay
              }`}
        </>
      </td>
      <td className={classNames(styles.cell, styles.iconCard)}>
        <Trash onClick={setModal.bind(null, true)} />
      </td>
      {modal && (
        <ModalBase
          onClickYes={onRemove}
          onClickNo={setModal.bind(null, false)}
          title={`Удалить водителя\n${cardInfo.username}?`}
        />
      )}
    </tr>
  );
};

export default StaffCard;
