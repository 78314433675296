import React, { FC, SyntheticEvent } from 'react';
import classNames from 'classnames';
import styles from './CheckBox.module.scss';
import { ReactComponent as OkIcon } from '../../../assets/icons/ok.svg';

type Props = {
    value?: number
    link?: string
    labelLeft?: string
    label?: string
    checked?: boolean | boolean[]
    onChange?: (checked: boolean) => void
    href?: string
    classes?: {
        root?: string
    }

}
/**
 *
 * @param label
 * @param value
 * @param link
 * @param checked
 * @param onChange
 * @param href
 * @returns
 */
const CheckBox: FC<Props> = ({
  label,
  value,
  link,
  checked = false,
  onChange,
  href,
  classes,
  labelLeft,
}) => {
  const handleClick = (e: SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onChange && onChange(!checked);
  };

  return (
    <div
      onClick={(e) => handleClick(e)}
      className={classNames(styles.root, classes?.root)}
    >
      {labelLeft && <div className={styles.labelLeft}>{labelLeft}</div>}
      <div

        className={classNames(styles.check, { [styles.checked]: checked })}
      >
        {checked && (
        <div className={styles.mark}>
          <OkIcon />
        </div>
        )}

      </div>
      {label && <div className={styles.label}>{label}</div>}
    </div>
  );
};

export default CheckBox;
