import styles from "../DriverCard.module.scss";
import { Driver, ValidDriverErrors } from "../../../../types/driver";
import { TextWithHintIcon } from "../../../simples/TextWithHintIcon";
import { Input } from "../../../simples/Input";
import { HintText } from "../../../simples/HintText";
import { NUMBER_AS_LOGIN_AND_PASSWORD_AS_NUMBER } from "../../../../constants/hints";
import { InputAfetIcons, TypeInput } from "../../../simples/Input/Input";
import classNames from "classnames";

type Props = {
  data: Driver;
  setData: (field: keyof Driver, value: any) => void;
  errors: ValidDriverErrors | undefined;
  isEdditing?: boolean;
};

const LeftSiteDriverCard = ({ data, setData, errors, isEdditing }: Props) => (
  <div className={styles.topSiteContainer}>
    <TextWithHintIcon classes={{ root: styles.topTitleHint }} title="ФИО*" />
    <Input
      ml={25}
      error={errors?.fio && errors.fio[0]}
      classes={{ input: styles.input }}
      onChange={setData.bind(null, "fio")}
      value={data.fio}
      placeholder="Введите имя"
      afterIcon={isEdditing && InputAfetIcons.PENSIL}
    />
    <TextWithHintIcon
      classes={{ root: styles.title }}
      title="Номер телефона*"
    />
    <Input
      error={errors?.phone && errors.phone[0]}
      classes={{ input: styles.input }}
      onChange={setData.bind(null, "phone")}
      value={data.phone}
      mask={"+7 (999) 999-99-99"}
      placeholder={"+7 (___) ___-__-__"}
      afterIcon={isEdditing && InputAfetIcons.PENSIL}
    />
    <TextWithHintIcon classes={{ root: styles.title }} title="E-mail*" />
    <Input
      ml={35}
      error={errors?.email && errors.email[0]}
      classes={{ input: styles.input }}
      onChange={setData.bind(null, "email")}
      value={data.email || ""}
      placeholder={"Введите email"}
      afterIcon={isEdditing && InputAfetIcons.PENSIL}
      autoComplete="off"
    />
    <TextWithHintIcon classes={{ root: styles.title }} title="Автомобиль*" />
    <Input
      ml={30}
      error={errors?.autoMark && errors.autoMark[0]}
      classes={{ input: styles.input }}
      onChange={setData.bind(null, "autoMark")}
      value={data.autoMark}
      placeholder={"Введите наименование"}
      afterIcon={isEdditing && InputAfetIcons.PENSIL}
      autoComplete="off"
    />
    <TextWithHintIcon classes={{ root: styles.title }} title="Пароль*" />
    <Input
      typeProps={TypeInput.PASSWORD}
      error={errors?.password && errors?.password[0]}
      placeholder={"Пароль"}
      value={data.password}
      onChange={setData.bind(null, "password")}
    />
    <TextWithHintIcon classes={{ root: styles.title }} title="Гос номер*" />
    <Input
      error={errors?.autoNum && errors.autoNum[0]}
      classes={{ input: classNames(styles.input, styles.autocapitolize) }}
      onChange={setData.bind(null, "autoNum")}
      value={data.autoNum}
      mask={"a 999 aa"}
      placeholder={"A 000 AA"}
      afterIcon={isEdditing && InputAfetIcons.PENSIL}
    />
    <TextWithHintIcon classes={{ root: styles.title }} title="Номер карты*" />
    <Input
      ml={25}
      disabled={true}
      error={errors?.codeAuto && errors.codeAuto[0]}
      classes={{ input: styles.input }}
      onChange={setData.bind(null, "codeAuto")}
      value={data.codeAuto}
      placeholder={"Введите номер"}
    />

    <span className={styles.hint}>
      <HintText mt={24}>{NUMBER_AS_LOGIN_AND_PASSWORD_AS_NUMBER}</HintText>
    </span>
  </div>
);

export default LeftSiteDriverCard;
