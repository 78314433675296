import { Button } from "../Button";
import styles from "./ModalBase.module.scss";

type Props = {
  title?: string;
  onClickYes?: () => void;
  onClickNo?: () => void;
};

const ModalBase = ({ onClickYes, onClickNo, title }: Props) => (
  <div className={styles.root}>
    <div className={styles.border}>
      <div className={styles.container}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.buttonsContainer}>
          {onClickYes && (
            <Button className={styles.confirm} onClick={onClickYes}>
              Да
            </Button>
          )}
          {onClickNo && (
            <Button className={styles.disagree} onClick={onClickNo}>
              Нет
            </Button>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default ModalBase;
