import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query/react';
import authReducer from '../store/slice/auth';
import appReducer from '../store/slice/app';
import { transAPI } from '../services/Transactions';
// import storageReducer from '../store/slice/storage'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    app: appReducer,
    // storage: storageReducer
    [transAPI.reducerPath]: transAPI.reducer,

  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false
    }).concat(transAPI.middleware)
    // .concat(ordersApi.middleware)
  }
});

setupListeners(store.dispatch);

// Вывод типов `RootState` и `AppDispatch` из стора.
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
