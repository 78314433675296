import { UserTypes, VatTypes } from "../../../../constants/user";
import { CompanyData, ValidCompanyDataErrors } from "../../../../types/user";
import HintText from "../../../simples/HintText/HintText";
import { Input } from "../../../simples/Input";
import { RadioButton } from "../../../simples/RadioButton";
import { TextWithHintIcon } from "../../../simples/TextWithHintIcon";
import styles from "../UserFields.module.scss";
import { GENERATE_PASSWORD_HINT } from "../../../../constants/hints";

type Props = {
  data: CompanyData;
  setData: (field: keyof CompanyData, value: any) => void;
  errors: ValidCompanyDataErrors | undefined;
  isBeenChange?: boolean;
};

const LeftPartUserFields = ({
  data,
  setData,
  errors,
  isBeenChange = true,
}: Props) => {
  const withVat = data["form-of-organization"] === VatTypes.withVat;
  const isLegalType = data["type-of-ownership"] === UserTypes.legal;

  return (
    <div className={styles.topSiteContainer}>
      <TextWithHintIcon classes={{ root: styles.topTitleHint }} title="ИНН" />
      <Input
        error={errors?.inn && errors.inn[0]}
        classes={{ input: styles.input }}
        onChange={setData.bind(null, "inn")}
        value={data.inn}
        mask={"999999999999"}
        placeholder="Введите номер"
        disabled={!isBeenChange}
      />
      <TextWithHintIcon
        classes={{ root: styles.titleHint }}
        title="Форма организации"
      />
      <RadioButton
        isChoosed={withVat}
        title="С НДС"
        onClick={
          isBeenChange
            ? setData.bind(null, "form-of-organization", VatTypes.withVat)
            : () => null
        }
      />
      <RadioButton
        classes={{ root: styles.bottomRadio }}
        isChoosed={!withVat}
        title="БЕЗ НДС"
        onClick={
          isBeenChange
            ? setData.bind(null, "form-of-organization", VatTypes.withoutVat)
            : () => null
        }
      />

      <TextWithHintIcon
        classes={{ root: styles.titleHint }}
        title="Форма собственности"
      />
      <RadioButton
        isChoosed={!isLegalType}
        title="Индивидуальный предприниматель"
        onClick={
          isBeenChange
            ? setData.bind(null, "type-of-ownership", UserTypes.individual)
            : () => null
        }
      />
      <RadioButton
        classes={{ root: styles.bottomRadio }}
        isChoosed={isLegalType}
        title="Юридическое лицо"
        onClick={
          isBeenChange
            ? setData.bind(null, "type-of-ownership", UserTypes.legal)
            : () => null
        }
      />
      <TextWithHintIcon classes={{ root: styles.title }} title="Телефон*" />
      <Input
        error={errors?.phone && errors.phone[0]}
        classes={{ input: styles.input }}
        onChange={setData.bind(null, "phone")}
        value={data.phone}
        mask={"+7 (999) 999-99-99"}
        disabled={!isBeenChange}
        placeholder={"+7 (___) ___-__-__"}
      />
      <TextWithHintIcon classes={{ root: styles.title }} title="E-mail*" />
      <Input
        error={errors?.email && errors.email[0]}
        classes={{ input: styles.input }}
        onChange={setData.bind(null, "email")}
        value={data.email}
        placeholder="Введите email"
        disabled={!isBeenChange}
      />
      <span className={styles.hint}>
        <HintText mt={24}>{GENERATE_PASSWORD_HINT}</HintText>
      </span>
    </div>
  );
};

export default LeftPartUserFields;
