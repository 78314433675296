import classNames from 'classnames';
import React, { FC } from 'react';
import { RouteProps } from 'react-router-dom';
import styles from './BlockWrapper.module.scss';

type Props = RouteProps & {
    isFull?: boolean
    className?: string
}

const BlockWrapper: FC<Props> = ({ isFull, children, className }) => (
  <div style={{ width: isFull ? '100%' : '' }} className={classNames(styles.root, className)}>
    <div className={styles.content}>
      {children}
    </div>
  </div>
);
export default React.memo(BlockWrapper);
