import styles from "../UserFields.module.scss";
import { CompanyData, ValidCompanyDataErrors } from "../../../../types/user";
import { Input } from "../../../simples/Input";
import { TextWithHintIcon } from "../../../simples/TextWithHintIcon";

type Props = {
  data: CompanyData;
  setData: (field: keyof CompanyData, value: string) => void;
  errors: ValidCompanyDataErrors | undefined;
};

const RightPartIndividualUserFields = ({ data, setData, errors }: Props) => (
  <div className={styles.topSiteContainer}>
    <TextWithHintIcon
      classes={{ root: styles.topTitle }}
      title={"Фамилия индивидуального предпринимателя"}
    />
    <Input
      ml={25}
      error={errors?.["surname-ip"] && errors["surname-ip"][0]}
      classes={{ input: styles.input }}
      onChange={setData.bind(null, "surname-ip")}
      value={data["surname-ip"]}
      placeholder="Введите фамилию"
    />

    <TextWithHintIcon
      classes={{ root: styles.title }}
      title="Имя индивидуального предпринимателя"
    />
    <Input
      ml={25}
      error={errors?.["name-ip"] && errors["name-ip"][0]}
      classes={{ input: styles.input }}
      onChange={setData.bind(null, "name-ip")}
      value={data["name-ip"]}
      placeholder="Введите имя"
    />

    <TextWithHintIcon
      classes={{ root: styles.title }}
      title="Отчество индивидуального предпринимателя"
    />
    <Input
      ml={25}
      error={errors?.["patronymic-ip"] && errors["patronymic-ip"][0]}
      classes={{ input: styles.input }}
      onChange={setData.bind(null, "patronymic-ip")}
      value={data["patronymic-ip"]}
      placeholder="Введите отчество"
    />
    <TextWithHintIcon classes={{ root: styles.title }} title="ОГРНИП" />
    <Input
      ml={15}
      error={errors?.["ogrn"] && errors["ogrn"][0]}
      classes={{ input: styles.input }}
      onChange={setData.bind(null, "ogrn")}
      value={data["ogrn"]}
      placeholder="Введите номер"
    />
    <TextWithHintIcon classes={{ root: styles.title }} title="ОКВЭД" />
    <Input
      ml={9}
      error={errors?.["okveds"] && errors["okveds"][0]}
      classes={{ input: styles.input }}
      onChange={setData.bind(null, "okveds")}
      value={data["okveds"]}
      placeholder="Введите значение"
    />
  </div>
);

export default RightPartIndividualUserFields;
